export const updateCartItemsAfterDeleteItem = (state, deleteItem) => {
  for (var i = 0; i < state.cartItems.length; i++) {
    if (state.cartItems[i].CartItemID === deleteItem.CartItemID) {
      state.cartItems.splice(i, 1);
    }
  }

  return state.cartItems;
};

export const updateCartAfterDeleteItem = (state, deleteItem) => {
  var num = state.cart.CartPrice - deleteItem.ItemTotalPrice;
  const fixedNumber = parseFloat(num.toFixed(2));

  var cartData = {
    CartID: state.userInfo.userID,
    OrderType: state.cart.OrderType,
    CartPrice: fixedNumber,
  };
  if (state.cart.CartPrice < 0) {
    cartData.CartPrice = 0.0;
  }

  return cartData;
};
