import React, { useContext, useState, useEffect } from "react";
import ApplicationContext from "../../ApplicationContext";
import "./order.scss";
import Navbar from "../../components/navbar/Navbar";
import OrderOptions from "../../components/order/OrderOptions";
import OrderTipOptions from "../../components/order/OrderTipOptions";
import OrderDetails from "../../components/order/OrderDetails";
import Footer from "../../components/footer/Footer";
import {
  APPLICATION,
  BASE_API,
  ORDER_FEE,
  TAX_PERCENTAGE,
  STORE_NAME_CODE,
} from "../../constants";
import CheckoutForm from "../../components/checkout/CheckoutForm";
import axios from "axios";
import StoreContext from "../../StoreContext";
import MxCheckoutForm from "../../components/checkout/MxCheckoutForm";

function Order(props) {
  const appContext = useContext(ApplicationContext);
  const storeContext = useContext(StoreContext);

  const [showWelcome] = useState(false);

  const [nowOrLater, setNowOrLater] = useState("Now");
  const [orderType, setOrderType] = useState();
  const [selectedDay, setSelectedDay] = useState();
  const [selectedTime, setSelectedTime] = useState(null);
  const [finalTotal, setFinalTotal] = useState();
  const [taxAmount, setTaxAmount] = useState();
  const [tipAmount, setTipAmount] = useState({
    amount: 0.0,
    type: null,
  });
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [isExtraDeliveryFee, setIsExtraDeliveryFee] = useState(false);

  const [discountedSubtotal, setDiscountedSubtotal] = useState();
  const [discountedTax, setDiscountedTax] = useState();
  const [discountedTotal, setDiscountedTotal] = useState();

  const [isCashAdjustment, setIsCashAdjustment] = useState();

  const [customerPaymentProfiles, setCustomerPaymentProfiles] = useState([]);

  const [invalidDayItems, setInvalidDayItems] = useState(false);
  const [invalidTypeItems, setInvalidTypeItems] = useState(false);
  const [invalidTimeItems, setInvalidTimeItems] = useState(false);

  async function getCustomerProfile(id) {
    if (APPLICATION) {
      if (storeContext.mxMerchant === "true") {
        const dataresp = await axios.get(
          `${BASE_API}api/MxMerchant/get-mxmerchant-customer-vaultedpayment/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Application: APPLICATION,
            },
          }
        );
        setCustomerPaymentProfiles(JSON.parse(dataresp.data.content));
      } else {
        const dataresp = await axios.get(
          `${BASE_API}api/AuthorizeNet/GetCustomerPaymentMethods/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Application: APPLICATION,
            },
          }
        );
        setCustomerPaymentProfiles(dataresp.data.profile.paymentProfiles);
      }
    }
  }

  useEffect(() => {
    if (storeContext.mxMerchant !== "true" && appContext.userInfo.customerID) {
      getCustomerProfile(appContext.userInfo.customerID);
    } else if (
      storeContext.mxMerchant === "true" &&
      appContext.userInfo.mxCustomerID
    ) {
      getCustomerProfile(appContext.userInfo.mxCustomerID);
    }
  }, []);

  useEffect(() => {
    var tax;
    var final = 0;
    var currentDeliveryFee = 0;
    //IF theres a chargePercent it means only PART of the order is getting CHARGED (the chargePercent * 100) (discounted totals ARE calculated)
    if (storeContext.chargePercent) {
      var discountTax;
      tax = appContext.cart.CartPrice * TAX_PERCENTAGE;
      discountTax =
        appContext.cart.CartPrice * TAX_PERCENTAGE * storeContext.chargePercent;
      discountTax = parseFloat(
        (Math.round(discountTax * 100) / 100).toFixed(2)
      );
      if (tipAmount.amount > 0) {
        var taxFromTip = tipAmount.amount * TAX_PERCENTAGE;
        taxFromTip = parseFloat(taxFromTip.toFixed(2));
        discountTax = parseFloat((discountTax + taxFromTip).toFixed(2));
        setDiscountedTax(discountTax);
      } else {
        setDiscountedTax(discountTax);
      }
      tax = parseFloat((Math.round(tax * 100) / 100).toFixed(2));
      if (tipAmount.amount > 0) {
        var taxFromTipp = tipAmount.amount * TAX_PERCENTAGE;
        taxFromTipp = parseFloat(taxFromTipp.toFixed(2));
        tax = parseFloat((tax + taxFromTipp).toFixed(2));
        setTaxAmount(tax);
      } else {
        setTaxAmount(tax);
      }

      var discountCart;
      var discountFinal;
      discountCart = appContext.cart.CartPrice * storeContext.chargePercent;
      discountCart = parseFloat(
        (Math.round(discountCart * 100) / 100).toFixed(2)
      );
      setDiscountedSubtotal(discountCart);
      if (orderType === "Delivery") {
        if(!isExtraDeliveryFee) {
          currentDeliveryFee = storeContext.deliveryFee;
          setDeliveryFee(storeContext.deliveryFee);
        } else { 
          currentDeliveryFee = deliveryFee;
          setDeliveryFee(deliveryFee);
        }
      } else {
        currentDeliveryFee = 0;
        setDeliveryFee(0);
      }
      discountFinal =
        currentDeliveryFee +
        parseFloat(tipAmount.amount) +
        discountTax +
        ORDER_FEE +
        discountCart;
      discountFinal = (Math.round(discountFinal * 100) / 100).toFixed(2);
      if (storeContext.isCashAdjustment > 0) {
        setIsCashAdjustment(
          parseFloat(
            (
              Math.round(discountFinal * storeContext.isCashAdjustment * 100) /
              100
            ).toFixed(2)
          )
        );
        discountFinal = (
          parseFloat(discountFinal) +
          parseFloat(
            (
              Math.round(discountFinal * storeContext.isCashAdjustment * 100) /
              100
            ).toFixed(2)
          )
        ).toFixed(2);
      }
      setDiscountedTotal(discountFinal);
      final =
        currentDeliveryFee +
        parseFloat(tipAmount.amount) +
        tax +
        ORDER_FEE +
        appContext.cart.CartPrice;
      final = (Math.round(final * 100) / 100).toFixed(2);
      if (storeContext.isCashAdjustment > 0) {
        final = (
          parseFloat(final) +
          parseFloat(
            (
              Math.round(final * storeContext.isCashAdjustment * 100) / 100
            ).toFixed(2)
          )
        ).toFixed(2);
      }
      setFinalTotal(final);
    } else {
      //ELSE is NOT a chargePercent so it means the full order amount is getting charged (NO discounted totals calculated)
      tax = appContext.cart.CartPrice * TAX_PERCENTAGE;
      tax = parseFloat((Math.round(tax * 100) / 100).toFixed(2));

      if (tipAmount.amount > 0) {
        var taxFromTip2 = tipAmount.amount * TAX_PERCENTAGE;
        taxFromTip2 = parseFloat(taxFromTip2.toFixed(2));
        tax = parseFloat((tax + taxFromTip2).toFixed(2));
        setTaxAmount(tax);
      } else {
        setTaxAmount(tax);
      }

      if (orderType === "Delivery") {
        if(!isExtraDeliveryFee) {
          currentDeliveryFee = storeContext.deliveryFee;
          setDeliveryFee(storeContext.deliveryFee);
        } else { 
          currentDeliveryFee = deliveryFee;
          setDeliveryFee(deliveryFee);
        }
      } else {
        currentDeliveryFee = 0;
        setDeliveryFee(0);
      }

      final =
        currentDeliveryFee +
        parseFloat(tipAmount.amount) +
        tax +
        ORDER_FEE +
        appContext.cart.CartPrice;
      final = (Math.round(final * 100) / 100).toFixed(2);
      if (storeContext.isCashAdjustment > 0) {
        setIsCashAdjustment(
          parseFloat(
            (
              Math.round(final * storeContext.isCashAdjustment * 100) / 100
            ).toFixed(2)
          )
        );
      }
      setFinalTotal(final);
    }
  }, [props, tipAmount]);

  const getLatLong = async (addressToDeliver, newNames) => {
    const apiKey = "AIzaSyC0286rXJ9WGiFAPZO8oQyWsh4M4fV0ag8";
    const storeAddress = storeContext.address;
    var customerAddress;
    if(newNames === true) {
      customerAddress =
      addressToDeliver.streetAddress +
      ", " +
      addressToDeliver.city +
      ", " +
      addressToDeliver.stateName +
      " " +
      addressToDeliver.zipCode;
    } else {
      customerAddress =
      addressToDeliver.street +
      ", " +
      addressToDeliver.city +
      ", " +
      addressToDeliver.state +
      " " +
      addressToDeliver.zipCode;
    }

    try {
      //STORE COORDINATES
      const response = await axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
          params: {
            address: storeAddress,
            key: apiKey,
          },
        }
      );
      // Extract latitude and longitude from the response
      var storeCoordinates = {
        lat: response.data.results[0].geometry.location.lat,
        lng: response.data.results[0].geometry.location.lng,
      };

      //DELIVERY COORDINATES
      const response2 = await axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
          params: {
            address: customerAddress,
            key: apiKey,
          },
        }
      );
      // Extract latitude and longitude from the response
      var deliveryCoordinates = {
        lat: response2.data.results[0].geometry.location.lat,
        lng: response2.data.results[0].geometry.location.lng,
      };
      var delivery = deliveryCoordinates.lat + "," + deliveryCoordinates.lng;
      var store = storeCoordinates.lat + "," + storeCoordinates.lng;

      // Make a request to the Distance Matrix API
      return await fetchDistance(delivery, store, addressToDeliver);
    } catch (error) {
      console.error("Error fetching geocode:", error);
      throw error; // Rethrow the error so it can be caught by the caller
    }
  };

  const fetchDistance = async (delivery, store) => {
    return new Promise((resolve, reject) => {
      const service = new window.google.maps.DistanceMatrixService();
  
      function callback(response, status) {
        if (status === "OK") {
          const distanceValue = response.rows[0].elements[0].distance.value;
          const distanceInMiles = distanceValue / 1609.344;
  
          console.log(distanceInMiles);

          var fee;
          if (STORE_NAME_CODE === "frankspizzablairstown") {
            if (distanceInMiles > 8) {
              fee = storeContext.deliveryFee + 5;
              setDeliveryFee(fee);
              setIsExtraDeliveryFee(true);
            } else if (distanceInMiles > 7) {
              fee = storeContext.deliveryFee + 4;
              setDeliveryFee(fee);
              setIsExtraDeliveryFee(true);
            } else if (distanceInMiles > 6) {
              fee = storeContext.deliveryFee + 3;
              setDeliveryFee(fee);
              setIsExtraDeliveryFee(true);
            } else if (distanceInMiles > 5) {
              fee = storeContext.deliveryFee + 2;
              setDeliveryFee(fee);
              setIsExtraDeliveryFee(true);
            } else if (distanceInMiles > 4) {
              fee = storeContext.deliveryFee + 1;
              setDeliveryFee(fee);
              setIsExtraDeliveryFee(true);
            } else {
              setDeliveryFee(storeContext.deliveryFee);
              setIsExtraDeliveryFee(false);
            }
          }
  
          resolve(distanceInMiles); // Resolve the promise with the distance
        } else {
          console.error("Error:", status);
          reject(status); // Reject the promise with the error status
        }
      }
  
      service.getDistanceMatrix(
        {
          origins: [store],
          destinations: [delivery],
          travelMode: "DRIVING",
        },
        callback
      );
    });
  };
  
  useEffect(() => {
    checkItemsDayRestrictions();
    checkItemsTypeRestrictions();
    checkItemsTimeRestrictions();
  }, [nowOrLater, selectedDay, orderType, selectedTime]);

  async function checkItemsDayRestrictions() {
    var dayName;
    if (nowOrLater === "Later" && selectedDay) {
      const date = new Date(selectedDay.mysqlDate);
      const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "long" });
      dayName = dayOfWeek;
    } else {
      // Create a new Date object with the current date
      const currentDate = new Date();
      // Get the day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
      const dayOfWeek = currentDate.getDay();
      // Array to map day numbers to day names
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      // Get the name of the day
      dayName = days[dayOfWeek];
    }

    var allItemsValid = true;
    for (var i = 0; i < appContext.cartItems.length; i++) {
      if (appContext.cartItems[i].OrderDay) {
        if (dayName !== appContext.cartItems[i].OrderDay) {
          setInvalidDayItems(true);
          allItemsValid = false;
        }
      }
    }
    if (allItemsValid) {
      setInvalidDayItems(false);
    }
  }

  async function checkItemsTypeRestrictions() {
    var allItemsValid = true;
    for (var i = 0; i < appContext.cartItems.length; i++) {
      if (appContext.cartItems[i].OrderType) {
        if (orderType !== appContext.cartItems[i].OrderType) {
          setInvalidTypeItems(true);
          allItemsValid = false;
        }
      }
    }
    if (allItemsValid) {
      setInvalidTypeItems(false);
    }
  }

  async function checkItemsTimeRestrictions() {
    var currentTime;
    if (nowOrLater === "Later" && selectedTime) {
      currentTime = selectedTime.shortTime;
    } else {
      var now = new Date();
      var hours = now.getHours();
      var minutes = now.getMinutes();
      // Add leading zero if necessary
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      // Construct the time string
      currentTime = hours + ":" + minutes;
    }

    var allItemsValid = true;
    for (var i = 0; i < appContext.cartItems.length; i++) {
      // Split the OrderTime string into start and end times
      if (appContext.cartItems[i].OrderTime) {
        var [startTime, endTime] = appContext.cartItems[i].OrderTime.split("-");
        var formattedStartTime = startTime;
        var formattedEndTime = endTime;

        const isBetween = isTimeBetween(
          formattedStartTime,
          formattedEndTime,
          currentTime
        );

        if (!isBetween) {
          setInvalidTimeItems(true);
          allItemsValid = false;
        }
      }
    }
    if (allItemsValid) {
      setInvalidTimeItems(false);
    }
  }

  function isTimeBetween(startTime, endTime, currentTime) {
    const [startHour, startMinute] = startTime.split(":");
    const [endHour, endMinute] = endTime.split(":");
    const [currentHour, currentMinute] = currentTime.split(":");

    const startDate = new Date();
    startDate.setHours(startHour, startMinute, 0);

    const endDate = new Date();
    endDate.setHours(endHour, endMinute, 0);

    const currentDate = new Date();
    currentDate.setHours(currentHour, currentMinute, 0);

    return currentDate >= startDate && currentDate <= endDate;
  }

  return (
    <>
      <Navbar
        setAuthKey={props.setAuthKey}
        setShowAuthenticateModal={props.setShowAuthenticateModal}
        setAppContext={props.setAppContext}
        discountEndDate={props.discountEndDate}
        showWelcome={showWelcome}
      />
      {appContext.appContextuserInfo !== null ? (
        <div className="order-page">
          <h2 className="orderPage_title">
            Review My Order
          </h2>

          {(invalidDayItems || invalidTypeItems || invalidTimeItems) && (
            <div className="card p-2 my-1 mx-3 text-center">
              There are item(s) in your cart are invalid for order at the
              selected time.
            </div>
          )}

          <OrderOptions
            getLatLong={getLatLong}
            deliveryFee={deliveryFee}
            setDeliveryFee={setDeliveryFee}
            isExtraDeliveryFee={isExtraDeliveryFee}
            setIsExtraDeliveryFee={setIsExtraDeliveryFee}
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
            orderType={orderType}
            setOrderType={setOrderType}
            nowOrLater={nowOrLater}
            setNowOrLater={setNowOrLater}
            selectedAddress={props.selectedAddress}
            setSelectedAddress={props.setSelectedAddress}
            setAppContext={props.setAppContext}
          />

          <OrderTipOptions
            tipAmount={tipAmount}
            setTipAmount={setTipAmount}
            orderType={orderType}
            taxAmount={taxAmount}
            setTaxAmount={setTaxAmount}
            discountedTax={discountedTax}
            setDiscountedTax={setDiscountedTax}
          />

          <OrderDetails
            deliveryFee={deliveryFee}
            orderType={orderType}
            tipAmount={tipAmount}
            taxAmount={taxAmount}
            finalTotal={finalTotal}
            isCashAdjustment={isCashAdjustment}
            discountedSubtotal={discountedSubtotal}
            discountedTax={discountedTax}
            discountedTotal={discountedTotal}
          />

          {storeContext.mxMerchant !== "true" ? (
            <CheckoutForm
              deliveryFee={deliveryFee}
              isCashAdjustment={isCashAdjustment}
              invalidDayItems={invalidDayItems}
              invalidTypeItems={invalidTypeItems}
              invalidTimeItems={invalidTimeItems}
              orderType={orderType}
              tipAmount={tipAmount}
              taxAmount={taxAmount}
              nowOrLater={nowOrLater}
              selectedDay={selectedDay}
              selectedTime={selectedTime}
              selectedAddress={props.selectedAddress}
              customerPaymentProfiles={customerPaymentProfiles}
              finalTotal={finalTotal}
              discountedSubtotal={discountedSubtotal}
              discountedTax={discountedTax}
              discountedTotal={discountedTotal}
              setAppContext={props.setAppContext}
            />
          ) : (
            <MxCheckoutForm
              deliveryFee={deliveryFee}
              isCashAdjustment={isCashAdjustment}
              invalidDayItems={invalidDayItems}
              invalidTypeItems={invalidTypeItems}
              invalidTimeItems={invalidTimeItems}
              orderType={orderType}
              tipAmount={tipAmount}
              taxAmount={taxAmount}
              nowOrLater={nowOrLater}
              selectedDay={selectedDay}
              selectedTime={selectedTime}
              selectedAddress={props.selectedAddress}
              customerPaymentProfiles={customerPaymentProfiles}
              finalTotal={finalTotal}
              discountedSubtotal={discountedSubtotal}
              discountedTax={discountedTax}
              discountedTotal={discountedTotal}
              setAppContext={props.setAppContext}
            />
          )}
        </div>
      ) : null}

      <Footer />
    </>
  );
}

export default Order;
