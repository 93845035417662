import React, { useState, useContext } from "react";
import axios from "axios";
import { CreateGuid } from "../utils/authenticate/CreateGUID";
import { BASE_API, APPLICATION } from "../constants";
import ApplicationContext from "../ApplicationContext";
import StoreContext from "../StoreContext";
import { toast } from "react-toastify";
import { FaMapMarkerAlt } from "react-icons/fa";

function GoogleMapsInput(props) {
  const appContext = useContext(ApplicationContext);
  const storeContext = useContext(StoreContext);
  const [address, setAddress] = useState("");
  const [distance, setDistance] = useState(null);

  // Map of state names to their corresponding two-letter abbreviations
  const stateAbbreviations = {
    Alabama: "AL",
    Alaska: "AK",
    Arizona: "AZ",
    Arkansas: "AR",
    California: "CA",
    Colorado: "CO",
    Connecticut: "CT",
    Delaware: "DE",
    Florida: "FL",
    Georgia: "GA",
    Hawaii: "HI",
    Idaho: "ID",
    Illinois: "IL",
    Indiana: "IN",
    Iowa: "IA",
    Kansas: "KS",
    Kentucky: "KY",
    Louisiana: "LA",
    Maine: "ME",
    Maryland: "MD",
    Massachusetts: "MA",
    Michigan: "MI",
    Minnesota: "MN",
    Mississippi: "MS",
    Missouri: "MO",
    Montana: "MT",
    Nebraska: "NE",
    Nevada: "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    Ohio: "OH",
    Oklahoma: "OK",
    Oregon: "OR",
    Pennsylvania: "PA",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    Tennessee: "TN",
    Texas: "TX",
    Utah: "UT",
    Vermont: "VT",
    Virginia: "VA",
    Washington: "WA",
    "West Virginia": "WV",
    Wisconsin: "WI",
    Wyoming: "WY",
  };

  // Function to handle changes in the input field
  const handleChange = (event) => {
    setAddress(event.target.value);
  };

  // Function to handle selection from autocomplete suggestions
  const handlePlaceSelect = async (place) => {
    setAddress(place.formatted_address);

    // Extracting address components
    const addressComponents = {};
    place.address_components.forEach((component) => {
      addressComponents[component.types[0]] = component.long_name;
    });

    // Building the complete address
    const formattedAddress = {
      street: addressComponents.street_number
        ? addressComponents.street_number + " " + addressComponents.route
        : addressComponents.route,
      city: addressComponents.locality,
      state:
        stateAbbreviations[addressComponents.administrative_area_level_1] ||
        addressComponents.administrative_area_level_1,
      zipCode: addressComponents.postal_code,
      apartment: addressComponents.subpremise || null,
    };

    var distanceInMiles = await props.getLatLong(formattedAddress, false);

    console.log(distanceInMiles);

    if (distanceInMiles <= storeContext.deliveryMiles) {
      AddAddress(formattedAddress);
      props.setShowAddressInput(false);
    } else {
      toast.error(
        "This address is not in range for Delivery. Please choose another or address or select Pickup."
      );
      var input = document.getElementById("autocomplete-input");
      if (input) {
        input.value = "";
      }
    }
  };

  async function AddAddress(formattedAddress) {
    const residenceId = CreateGuid();
    var streetAddress = formattedAddress.street;
    var city = formattedAddress.city;
    var stateName = formattedAddress.state;
    var zipCode = formattedAddress.zipCode;
    var apartmentNumber = formattedAddress.apartment;
    try {
      await axios.post(
        `${BASE_API}api/User/AddAddress`,
        {
          residenceId,
          streetAddress,
          stateName,
          city,
          zipCode,
          apartmentNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            Application: APPLICATION,
          },
        }
      );

      var address = {
        residenceID: residenceId,
        Active: "Active",
        residence_UserID: appContext.userInfo.userID,
        streetAddress: streetAddress,
        stateName: stateName,
        city: city,
        zipCode: zipCode,
        apartmentNumber: apartmentNumber,
      };

      var addAddressToUserInfo = { ...appContext.userInfo, address };
      var newAppContext = {
        userInfo: addAddressToUserInfo,
        cart: appContext.cart,
        cartItems: appContext.cartItems,
      };
      props.setAppContext(newAppContext);
      localStorage.setItem("appContext", JSON.stringify(newAppContext));
      toast.success("Add Address Success");
      if (props.userAddresses !== undefined) {
        props.setUserAddresses(props.userAddresses.concat(address));
      } else {
        props.setUserAddresses([address]);
        props.setSelectedAddress(address);
      }

      var activeAddress = document.querySelector(".address.Active");
      if (activeAddress !== null) {
        activeAddress.classList.remove("Active");
      }
    } catch (error) {
      toast.error(
        "This address is not in range for Delivery. Please choose another or address or select Pickup."
      );
    }
  }

  // Initialize Google Autocomplete service
  const initAutocomplete = () => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById("autocomplete-input"),
      { types: ["geocode"] } // Limit to addresses only
    );

    // Listen for place selection
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      handlePlaceSelect(place);
    });
  };

  // Load Google Maps API script asynchronously
  const loadGoogleMapsScript = () => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC0286rXJ9WGiFAPZO8oQyWsh4M4fV0ag8&libraries=places`;
    script.onload = initAutocomplete;
    document.head.appendChild(script);
  };

  // Load Google Maps API script when component mounts
  React.useEffect(() => {
    loadGoogleMapsScript();
  }, []);

  return (
    <div className="google_maps_input_container">
      <div style={{ padding: "0 8px" }}>
        <FaMapMarkerAlt />
      </div>
      <input
        id="autocomplete-input"
        type="text"
        value={address}
        onChange={handleChange}
        placeholder="Enter your address"
      />
    </div>
  );
}

export default GoogleMapsInput;
