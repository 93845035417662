import React, { useContext } from "react";
import "./footer.scss";
import StoreContext from "../../StoreContext";
import { STORE_NAME_CODE } from "../../constants";

function Footer() {
  const storeContext = useContext(StoreContext);

  return (
    <div className="footer">
      <div className="contact">
        <h4>Contact Us</h4>
        <p>
          {storeContext.address}
          <br />
          <a href={`tel:${storeContext.phoneNumber}`}>
            {storeContext.phoneNumber}
          </a>
        </p>
        <p></p>
      </div>
      <div className="empowered">
        <h5 className="content">
          <div>Powered by</div>
          <a href="https://enterprisewebsolutions.co">
            Enterprise Web Solutions &#169;
          </a>
        </h5>
      </div>
      <div className="terms">
        <a href="/terms" className="link">Terms</a>
        <a href="/privacy" className="link">Privacy</a>
        <a href="/refund" className="link">Refund</a>
        <div className="cards">
          <img src="/pictures/visa.png" alt="" />
          <img src="/pictures/mastercard.png" alt="" />
          <img src="/pictures/americanexpress.png" alt="" />
          <img src="/pictures/discover.png" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Footer;
