import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { FaWindowClose } from "react-icons/fa";
import StoreContext from "../../StoreContext";
import "./hoursModal.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function HoursModal(props) {
  const storeContext = useContext(StoreContext);

  async function closeHoursModal() {
    props.setShowHoursModal(false);
  }

  function militaryTimeToUSTime(militaryTime) {
    // Split the military time string into hours and minutes
    const [hours, minutes] = militaryTime.split(":");

    // Convert hours to a number
    const militaryHours = parseInt(hours, 10);

    // Determine AM or PM
    const period = militaryHours < 12 ? "AM" : "PM";

    // Convert hours to US time (12-hour format)
    const usHours = militaryHours % 12 || 12;

    // Format the result as a string
    const usTime = `${usHours}:${minutes} ${period}`;

    return usTime;
  }
  return (
    <>
      <Modal className="hoursModal" show={props.showHoursModal}>
        <Modal.Body>
          <Modal.Header>
            <FaWindowClose
              onClick={closeHoursModal}
              style={{ width: "2em", height: "2em" }}
              className="text-danger"
            />
          </Modal.Header>
          <Tabs id="controlled-tab-example" className="mb-3 HoursTabs">
            <Tab eventKey="Pickup Hours" title="Pickup Hours">
              <div className="card">
                <div className="store_status">
                  {storeContext.open === "Open" ? (
                    <div className="open">{storeContext.open}</div>
                  ) : (
                    <div className="closed">{storeContext.open}</div>
                  )}
                </div>
                <div className="hoursModal_container">
                  <ul>
                    {storeContext.hours.map((day) => (
                      <li key={day.dayOfWeek}>
                        <div className="day">{day.dayOfWeek}:</div>
                        <div className="time">
                          {militaryTimeToUSTime(day.openTime) !== "12:00 AM" &&
                          militaryTimeToUSTime(day.closeTime) !== "12:00 AM" ? (
                            <>
                              {militaryTimeToUSTime(day.openTime)} -{" "}
                              {militaryTimeToUSTime(day.closeTime)}
                            </>
                          ) : (
                            <>CLOSED</>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Tab>
            <Tab eventKey="DeliveryHours" title="Delivery Hours">
              <div className="card">
                <div className="store_status">
                  {storeContext.deliveryOpen === "Open" ? (
                    <div className="open">{storeContext.deliveryOpen}</div>
                  ) : (
                    <div className="closed">{storeContext.deliveryOpen}</div>
                  )}
                </div>
                <div className="hoursModal_container">
                  <ul>
                    {storeContext.deliveryHours.map((day) => (
                      <li key={day.dayOfWeek}>
                        <div className="day">{day.dayOfWeek}:</div>
                        <div className="time">
                          {militaryTimeToUSTime(day.openTime) !== "12:00 AM" &&
                          militaryTimeToUSTime(day.closeTime) !== "12:00 AM" ? (
                            <>
                              {militaryTimeToUSTime(day.openTime)} -{" "}
                              {militaryTimeToUSTime(day.closeTime)}
                            </>
                          ) : (
                            <>CLOSED</>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default HoursModal;
