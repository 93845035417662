import React from "react";
import Navbar from "../../components/navbar/Navbar";
import "./terms.scss";
import Footer from "../../components/footer/Footer";

function Privacy(props) {
  return (
    <>
      <Navbar
        setAppContext={props.setAppContext}
        setAuthKey={props.setAuthKey}
        setShowAuthenticateModal={props.setShowAuthenticateModal}
      />
      <div className="terms_container">
        <body>
          <header>
            <h1 className="title fw-bold">PRIVACY POLICY</h1>
          </header>

          <section>
            <p>
              This privacy notice for Enterprise Web Solutions LLC ("we," "us,"
              or "our"), describes how and why we might collect, store, use,
              and/or share ("process") your information when you use our
              services ("Services"), such as when you:
            </p>

            <ul>
              <li>
                Visit our website at{" "}
                <a href="https://myorderportal.com">
                  https://myorderportal.com
                </a>
                , or any website of ours that links to this privacy notice
              </li>
              <li>
                Engage with us in other related ways, including any sales,
                marketing, or events
              </li>
            </ul>

            <p>
              Questions or concerns? Reading this privacy notice will help you
              understand your privacy rights and choices. If you do not agree
              with our policies and practices, please do not use our Services.
              If you still have any questions or concerns, please contact us at{" "}
              <a href="mailto:enterprisewebsolutions@outlook.com">
                enterprisewebsolutions@outlook.com
              </a>
              .
            </p>
          </section>

          <section>
            <h2>SUMMARY OF KEY POINTS</h2>

            <p>
              This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our table of
              contents below to find the section you are looking for.
            </p>

            <ul>
              <li>
                <strong>What personal information do we process?</strong> When
                you visit, use, or navigate our Services, we may process
                personal information depending on how you interact with us and
                the Services, the choices you make, and the products and
                features you use.
              </li>
              <li>
                <strong>
                  Do we process any sensitive personal information?
                </strong>{" "}
                We do not process sensitive personal information.
              </li>
              <li>
                <strong>
                  Do we receive any information from third parties?
                </strong>{" "}
                We do not receive any information from third parties.
              </li>
              <li>
                <strong>How do we process your information?</strong> We process
                your information to provide, improve, and administer our
                Services, communicate with you, for security and fraud
                prevention, and to comply with law.{" "}
              </li>
              <li>
                <strong>
                  In what situations and with which parties do we share personal
                  information?
                </strong>{" "}
                We may share information in specific situations and with
                specific third parties.
              </li>
              <li>
                <strong>How do we keep your information safe?</strong> We have
                organizational and technical processes and procedures in place
                to protect your personal information.{" "}
              </li>
              <li>
                <strong>What are your rights?</strong> Depending on where you
                are located geographically, the applicable privacy law may mean
                you have certain rights regarding your personal information.{" "}
              </li>
              <li>
                <strong>How do you exercise your rights?</strong> The easiest
                way to exercise your rights is by submitting a data subject
                access request, or by contacting us. We will consider and act
                upon any request in accordance with applicable data protection
                laws.
              </li>
              <li>
                <strong>
                  Want to learn more about what we do with any information we
                  collect?
                </strong>{" "}
              </li>
            </ul>
          </section>

          <section>
            <h2>TABLE OF CONTENTS</h2>

            <ol>
              <li>WHAT INFORMATION DO WE COLLECT?</li>
              <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
              <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
              <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
              <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
              <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
              <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
              <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
              <li>DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
              <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
              <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
              <li>
                HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                YOU?
              </li>
            </ol>
          </section>

          <section id="section1">
            <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
            <p>
              <strong>Personal information you disclose to us</strong>
            </p>
            <p>
              <em>
                In Short: We collect personal information that you provide to
                us.
              </em>
            </p>
            <p>
              We collect personal information that you voluntarily provide to us
              when you register on the Services, express an interest in
              obtaining information about us or our products and Services, when
              you participate in activities on the Services, or otherwise when
              you contact us.
            </p>
            <p>
              <strong>Personal Information Provided by You.</strong> The
              personal information that we collect depends on the context of
              your interactions with us and the Services, the choices you make,
              and the products and features you use. The personal information we
              collect may include the following:
            </p>
            <ul>
              <li>Names</li>
              <li>Phone numbers</li>
              <li>Email addresses</li>
              <li>Mailing addresses</li>
            </ul>
            <p>
              <strong>Sensitive Information.</strong> We do not process
              sensitive information.
            </p>
            <p>
              <strong>Payment Data.</strong> We may collect data necessary to
              process your payment if you make purchases, such as your payment
              instrument number, and the security code associated with your
              payment instrument. All payment data is stored by Mx Merchant. You
              may find their privacy notice link(s) here:{" "}
              <a href="https://www.mx.com/privacy-policy/">
                https://www.mx.com/privacy-policy/
              </a>
              .
            </p>
            <p>
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </p>
          </section>

          <section id="section2">
            <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
            <p>
              <em>
                In Short: We process your information to provide, improve, and
                administer our Services, communicate with you, for security and
                fraud prevention, and to comply with law. We may also process
                your information for other purposes with your consent.
              </em>
            </p>
            <p>
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </p>
            <ul>
              <li>
                To facilitate account creation and authentication and otherwise
                manage user accounts. We may process your information so you can
                create and log in to your account, as well as keep your account
                in working order.
              </li>
              <li>
                To fulfill and manage your orders. We may process your
                information to fulfill and manage your orders, payments,
                returns, and exchanges made through the Services.
              </li>
            </ul>
          </section>

          <section id="section3">
            <h2>
              3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </h2>
            <p>
              <em>
                In Short: We may share information in specific situations
                described in this section and/or with the following third
                parties.
              </em>
            </p>
            <p>
              We may need to share your personal information in the following
              situations:
            </p>
            <ul>
              <li>
                Business Transfers. We may share or transfer your information in
                connection with, or during negotiations of, any merger, sale of
                company assets, financing, or acquisition of all or a portion of
                our business to another company.
              </li>
            </ul>
          </section>

          <section id="section4">
            <h2>4. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
            <p>
              <em>
                In Short: We keep your information for as long as necessary to
                fulfill the purposes outlined in this privacy notice unless
                otherwise required by law.
              </em>
            </p>
            <p>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements).
            </p>
            <p>
              No purpose in this notice will require us keeping your personal
              information for longer than twelve (12) months past the
              termination of the user's account.
            </p>
            <p>
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </p>
          </section>

          <section id="section5">
            <h2>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
            <p>
              <em>
                In Short: We aim to protect your personal information through a
                system of organizational and technical security measures.
              </em>
            </p>
            <p>
              We have implemented appropriate and reasonable technical and
              organizational security measures designed to protect the security
              of any personal information we process. However, despite our
              safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
            </p>
            <p>
              Although we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the Services within a
              secure environment.
            </p>
          </section>

          <section id="section6">
            <h2>6. DO WE COLLECT INFORMATION FROM MINORS?</h2>
            <p>
              <em>
                In Short: We do not knowingly collect data from or market to
                children under 18 years of age.
              </em>
            </p>
            <p>
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services, you represent that you are
              at least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent’s use of the Services. If we
              learn that personal information from users less than 18 years of
              age has been collected, we will deactivate the account and take
              reasonable measures to promptly delete such data from our records.
              If you become aware of any data we may have collected from
              children under age 18, please contact us at{" "}
              <a href="mailto:enterprisewebsolutions@outlook.com">
                enterprisewebsolutions@outlook.com
              </a>
              .
            </p>
          </section>

          <section id="section7">
            <h2>7. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
            <p>
              <em>
                In Short: You may review, change, or terminate your account at
                any time.
              </em>
            </p>
            <p>
              <strong>Withdrawing your consent:</strong> If we are relying on
              your consent to process your personal information, you have the
              right to withdraw your consent at any time. You can withdraw your
              consent by contacting us using the details provided in the section
              "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
            </p>
            <p>
              However, please note that this will not affect the lawfulness of
              the processing before its withdrawal nor, when applicable law
              allows, will it affect the processing of your personal information
              conducted in reliance on lawful processing grounds other than
              consent.
            </p>
            <p>
              <strong>
                Opting out of marketing and promotional communications:
              </strong>{" "}
              You can unsubscribe from our marketing and promotional
              communications at any time by clicking on the unsubscribe link in
              the emails that we send or by contacting us using the details
              provided in the section "HOW CAN YOU CONTACT US ABOUT THIS
              NOTICE?" below. You will then be removed from the marketing lists.
              However, we may still communicate with you for service-related
              messages.
            </p>
            <h3>Account Information</h3>
            <p>
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </p>
            <ul>
              <li>
                Log in to your account settings and update your user account.
              </li>
              <li>
                Upon your request to terminate your account, we will deactivate
                or delete your account and information from our active
                databases. However, we may retain some information in our files
                to prevent fraud, troubleshoot problems, assist with any
                investigations, enforce our legal terms and/or comply with
                applicable legal requirements.
              </li>
            </ul>
            <p>
              If you have questions or comments about your privacy rights, you
              may email us at{" "}
              <a href="mailto:enterprisewebsolutions@outlook.com">
                enterprisewebsolutions@outlook.com
              </a>
              .
            </p>
          </section>

          <section id="section8">
            <h2>8. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
            <p>
              <em>
                In Short: We currently do not respond to Do-Not-Track browser
                signals.
              </em>
            </p>
            <p>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ("DNT") feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. As
              of now, we do not respond to DNT browser signals or any other
              mechanism that automatically communicates your choice not to be
              tracked online. If a standard for online tracking is adopted that
              we must follow in the future, we will inform you about that
              practice in a revised version of this privacy notice.
            </p>
          </section>

          <section id="section9">
            <h2>9. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
            <p>
              <em>
                In Short: If you are a resident of the United States, you are
                granted specific rights regarding access to your personal
                information.
              </em>
            </p>
            <p>
              <strong>
                What categories of personal information do we collect?
              </strong>
            </p>
            <p>
              We have collected the following categories of personal information
              in the past twelve (12) months:
            </p>
            <table>
              <tr>
                <th>Category</th>
                <th>Examples</th>
                <th>Collected</th>
              </tr>
              <tr>
                <td>A. Identifiers</td>
                <td>
                  Contact details, such as real name, alias, postal address,
                  telephone or mobile contact number, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, and account name
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>
                  B. Protected classification characteristics under state or
                  federal law
                </td>
                <td>Gender and date of birth</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>C. Commercial information</td>
                <td>
                  Transaction information, purchase history, financial details,
                  and payment information
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>D. Biometric information</td>
                <td>Fingerprints and voiceprints</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>E. Internet or other similar network activity</td>
                <td>
                  Browsing history, search history, online behavior, interest
                  data, and interactions with our and other websites,
                  applications, systems, and advertisements
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>F. Geolocation data</td>
                <td>Device location</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>
                  G. Audio, electronic, visual, thermal, olfactory, or similar
                  information
                </td>
                <td>
                  Images and audio, video or call recordings created in
                  connection with our business activities
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>H. Professional or employment-related information</td>
                <td>
                  Business contact details in order to provide you our Services
                  at a business level or job title, work history, and
                  professional qualifications if you apply for a job with us{" "}
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>I. Education Information</td>
                <td>Student records and directory information </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>J. Inferences drawn from collected personal information</td>
                <td>
                  Inferences drawn from any of the collected personal
                  information listed above to create a profile or summary about,
                  for example, an individual’s preferences and characteristics{" "}
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>K. Sensitive personal Information</td>
                <td></td>
                <td>NO</td>
              </tr>
            </table>
            <p>
              <strong>
                How do we use and share your personal information?
              </strong>
            </p>
            <p>
              Learn about how we use your personal information in the section,
              "HOW DO WE PROCESS YOUR INFORMATION?"
            </p>
            <p>
              <strong>Will your information be shared with anyone else?</strong>
            </p>
            <p>
              We may disclose your personal information with our service
              providers pursuant to a written contract between us and each
              service provider. Learn more about how we disclose personal
              information to in the section, "WHEN AND WITH WHOM DO WE SHARE
              YOUR PERSONAL INFORMATION?"
            </p>
            <p>
              We have not disclosed, sold, or shared any personal information to
              third parties for a business or commercial purpose in the
              preceding twelve (12) months. We will not sell or share personal
              information in the future belonging to website visitors, users,
              and other consumers.
            </p>
          </section>

          <section id="section10">
            <h2>10. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
            <p>
              <em>
                In Short: Yes, we will update this notice as necessary to stay
                compliant with relevant laws.
              </em>
            </p>
            <p>
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated "Revised" date, and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </p>
          </section>

          <section id="section11">
            <h2>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
            <p>
              If you have questions or comments about this notice, you may email
              us at{" "}
              <a href="mailto:enterprisewebsolutions@outlook.com">
                enterprisewebsolutions@outlook.com
              </a>{" "}
              or contact us by post at:
            </p>
            <p>
              <strong>Enterprise Web Solutions LLC</strong>
              <div>28 Gristmill Sparta, NJ 07871 United States</div>
            </p>
          </section>

          <section id="section12">
            <h2>
              12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </h2>
            <p>
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it. To request to
              review, update, or delete your personal information, please fill
              out and submit a data subject access request.
            </p>
          </section>
          <p>Last updated November 01, 2023</p>
        </body>
      </div>
      <Footer />
    </>
  );
}

export default Privacy;
