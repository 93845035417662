import React from "react";
import Navbar from "../../components/navbar/Navbar";
import "./terms.scss";
import Footer from "../../components/footer/Footer";

function Refund(props) {
  return (
    <>
      <Navbar
        setAppContext={props.setAppContext}
        setAuthKey={props.setAuthKey}
        setShowAuthenticateModal={props.setShowAuthenticateModal}
      />
      <div className="terms_container">
        <body>
          <header>
            <h1 className="title fw-bold">REFUND POLICY</h1>
          </header>

          <section>
            <h2>REFUNDS</h2>
            <p>
              Refunds will only be granted if the order is wrong or the order
              was not transferred to the intended customer correctly.
            </p>
          </section>
          <section>
            <h2>QUESTIONS</h2>
            <p>
              If you have any questions concerning our return policy, please
              contact us at:
            </p>
            <p>enterprisewebsolutions@outlook.com </p>
          </section>
          
          <p>Last updated November 01, 2023</p>
        </body>
      </div>
      <Footer />
    </>
  );
}

export default Refund;
