import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import { STORE_NAME_CODE } from "../../constants";

function NotAuthorized(props) {
  return (
    <>
      <Navbar
        setAuthKey={props.setAuthKey}
        setAppContext={props.setAppContext}
        setShowAuthenticateModal={props.setShowAuthenticateModal}
      />
      <div className="not_authorized">
        <h2 className="fs-2">Must be an admin to access this screen!</h2>
        <p>
          <a href={`/`}>Please return home</a>
        </p>
      </div>
      <Footer />
    </>
  );
}

export default NotAuthorized;
