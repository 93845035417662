import React, { useContext, useState } from "react";
import ApplicationContext from "../../../ApplicationContext";
import { toast } from "react-toastify";
import axios from "axios";
import { APPLICATION, BASE_API } from "../../../constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

function StoreClosures(props) {
  const appContext = useContext(ApplicationContext);

  const [addClosure, setAddClosure] = useState(false);

  const [date, setDate] = useState();
  const [formattedDate, setFormattedDate] = useState();

  const handleDateChange = (date) => {
    setDate(date);

    // Format the date and store it in the variable
    const formatted = date ? format(date, "yyyy-MM-dd") : "";
    setFormattedDate(formatted);
  };
  const isFutureDate = (date) => {
    const currentDate = new Date();
    return date > currentDate;
  };

  async function addStoreClosure() {
    if (formattedDate) {
      try {
        await axios.post(
          `${BASE_API}api/Store/AddStoreClosure/${formattedDate}`,
          { formattedDate },
          {
            headers: {
              Authorization: `Bearer ${appContext.userInfo.token}`,
              Application: APPLICATION,
            },
          }
        );

        props.getStoreClosures();

        toast.success(
          "Successfully added this date to the list closed days for this store."
        );
      } catch (err) {
        toast.error(err);
      }
    } else {
      toast.error("Please enter a date.");
    }
  }

  async function toggleAddClosure() {
    setAddClosure((addClosure) => !addClosure);
  }

  return (
    <div className="store_closures card">
      <div className="card-body">
        <div className="closures">
          {props.storeClosures?.map((c, index) => (
            <div key={index} className="day">
              {c.dateClosed}
            </div>
          ))}
        </div>
        <div className="add_closure">
          {addClosure && (
            <DatePicker
              selected={date}
              onChange={handleDateChange}
              dateFormat="yyyy-MM-dd"
              minDate={new Date()} // Set the minimum date to the current date
              filterDate={isFutureDate} // Only allow future dates
            />
          )}

          <div className="submit">
            {addClosure ? (
              <div className="submit_cancel">
                <button onClick={addStoreClosure}>Submit</button>
                <button className="cancel" onClick={toggleAddClosure}>
                  Cancel
                </button>
              </div>
            ) : (
              <button onClick={toggleAddClosure}>Add Closure</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoreClosures;
