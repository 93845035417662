import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import { FaPizzaSlice } from "react-icons/fa";
import { GiPizzaCutter } from "react-icons/gi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ApplicationContext from "../../ApplicationContext";
import { APPLICATION, BASE_API } from "../../constants";
import Footer from "../../components/footer/Footer";

function OrderComplete(props) {
  const navigate = useNavigate();
  const appContext = useContext(ApplicationContext);

  const [openOrder, setOpenOrder] = useState(false);
  const [multipleOrders, setMultipleOrders] = useState(false);
  const [orderETA, setOrderETA] = useState("");
  const [orderType, setOrderType] = useState("");

  useEffect(() => {
    async function getUserOrder() {
      try {
        var userId = appContext.userInfo.userID;
        var response = await axios.get(
          `${BASE_API}api/Order/GetUsersOpenOrders/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${appContext.userInfo.token}`,
              Application: APPLICATION,
            },
          }
        );
        setOrderType(response.data[0].orderType);
        if (response.data.length >= 1) {
          // Get the current time as a string
          const currentTimeString = response.data[0].requestedDate;
          // Create a Date object from the current time string
          const currentTime = new Date(currentTimeString);
          const string = response.data[0].orderETA;
          const matches = string.match(/\d{2}/g);
          const firstTwoDigits = parseInt(matches[0]);
          const nextTwoDigits = parseInt(matches[1]);

          // Add 30 minutes to the current time
          const newMinTime = new Date(
            currentTime.getTime() + firstTwoDigits * 60000
          ); // 30 minutes = 30 * 60 * 1000 milliseconds
          const newMaxTime = new Date(
            currentTime.getTime() + nextTwoDigits * 60000
          ); // 30 minutes = 30 * 60 * 1000 milliseconds

          // Format the new time as "HH:MM AM/PM"
          const hours = newMinTime.getHours() % 12 || 12; // Convert to 12-hour format
          const minutes = String(newMinTime.getMinutes()).padStart(2, "0");
          const amOrPm = newMinTime.getHours() < 12 ? "AM" : "PM";
          const formattedMinTime = `${hours}:${minutes} ${amOrPm}`;

          const hoursMax = newMaxTime.getHours() % 12 || 12; // Convert to 12-hour format
          const minutesMax = String(newMaxTime.getMinutes()).padStart(2, "0");
          const amOrPmMax = newMaxTime.getHours() < 12 ? "AM" : "PM";
          const formattedMaxTime = `${hoursMax}:${minutesMax} ${amOrPmMax}`;

          setOrderETA(formattedMinTime + " - " + formattedMaxTime);
          setOpenOrder(true);
        } else if (response.data.length > 1) {
          setMultipleOrders(true);
        } else {
          navigate("/");
        }
      } catch (error) {
        if (error.response.status === 401) {
          var newAppContext = {
            userInfo: null,
            cart: null,
            cartItems: null,
          };
          props.setAppContext(newAppContext);
          localStorage.setItem("appContext", JSON.stringify(newAppContext));
        }
        toast.error("Your session expired. Please try to login again");
      }
    }
    if (appContext.userInfo) {
      getUserOrder();
    }
  }, []);

  return (
    <>
      <Navbar
        setAuthKey={props.setAuthKey}
        setAppContext={props.setAppContext}
        setShowAuthenticateModal={props.setShowAuthenticateModal}
      />
      {openOrder ? (
        <div className="orderCompletion">
          <div className="thank_you">Thank you for your order!</div>
          {multipleOrders && <div>You have multiple open orders.</div>}
          {orderType === "Pickup" ? (
            <div className="order_ETA card">
              <div className="eta_header">Estimated Pickup time</div>
              <div className="time">{orderETA}</div>
            </div>
          ) : (
            <div className="order_ETA card">
              <div className="eta_header">Estimated Delivery time</div>
              <div className="time">{orderETA}</div>
            </div>
          )}
          <div className="icons">
            <FaPizzaSlice />
            <GiPizzaCutter />
          </div>
        </div>
      ) : (
        <div>You do not have any open orders currently.</div>
      )}
      <Footer />
    </>
  );
}

export default OrderComplete;
