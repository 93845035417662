import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./screens/home/Home";
import Order from "./screens/order/Order";
import "./app.scss";
import axios from "axios";
import MenuContext from "./MenuContext";
import ApplicationContext from "./ApplicationContext";
import StoreContext from "./StoreContext";
import { getMenuCategories } from "./utils/menu/GetMenuCategories";
import {
  APPLICATION,
  BASE_API,
  STORE_NAME,
  STORE_NAME_CODE,
  setStore,
} from "./constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkIfStoreIsOpen } from "./utils/store/CheckIfStoreIsOpen";
import { formatUsersCart } from "./utils/cart/FormatUsersCart";
import OrderComplete from "./screens/order/OrderComplete";
import Account from "./screens/account/Account";
import Admin from "./screens/admin/Admin";
import NotAuthorized from "./screens/admin/NotAuthorized";
import LoadingLogo from "./tools/LoadingLogo";
import Terms from "./screens/terms/Terms";
import AuthenticateModal from "./components/authenticateModal/AuthenticateModal";
import Privacy from "./screens/terms/Privacy";
import Refund from "./screens/terms/Refund";
import { checkIfStoreDeliveryIsOpen } from "./utils/store/CheckIfStoreDeliveryIsOpen";
import Rewards from "./screens/rewards/Rewards";

function App() {
  const [applicationNotFound, setApplicationNotFound] = useState();
  const [selectedAddress, setSelectedAddress] = useState();

  const [menuName, setMenuName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [discountEndDate, setDiscountEndDate] = useState();
  const [detailsAreLoading, setDetailsAreLoading] = useState(true);

  const [authKey, setAuthKey] = useState("Login");
  const [showAuthenticateModal, setShowAuthenticateModal] = useState();

  const [selectedItem, setSelectedItem] = useState();
  const [showItemModal, setShowItemModal] = useState(false);

  const [cartOpen, setCartOpen] = useState(false);
  const [showEditItemModal, setShowEditItemModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [storeContext, setStoreContext] = useState(
    JSON.parse(localStorage.getItem("storeContext")) || {
      open: null,
      deliveryOpen: null,
      name: null,
      nameCode: null,
      taxPercentage: null,
      chargePercent: null,
      discountPercent: null,
      menuName: null,
      categoryName: null,
      subCategoryName: null,
      hours: null,
      address: null,
      phoneNumber: null,
    }
  );

  const [menuContext, setMenuContext] = useState(
    JSON.parse(localStorage.getItem("menuContext")) || {
      items: null,
      extras: null,
      categories: null,
    }
  );

  const [appContext, setAppContext] = useState(
    JSON.parse(localStorage.getItem("appContext")) || {
      userInfo: null,
      cart: null,
      cartItems: null,
    }
  );

  const toastProps = {
    autoClose: 1250,
    position: "bottom-center",
    icon: false,
    closeButton: false,
    style: {
      width: "90vw",
      fontSize: "1.2rem",
    },
    closeOnClick: false,
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    const part1 = pathname.split("/")[1];

    const fetchMenuData = async () => {
      var newMenuContext;
      try {
        const response = await axios.get(`${BASE_API}api/Menu/MenuItems`, {
          headers: {
            Application: APPLICATION,
          },
        });
        const menuItems = response.data.item1;
        const menuItemExtras = response.data.item2;
        const categories = getMenuCategories(response.data.item1);
        newMenuContext = {
          items: menuItems,
          extras: menuItemExtras,
          categories: categories,
        };
        setMenuContext(newMenuContext);
        localStorage.setItem("menuContext", JSON.stringify(newMenuContext));
        setApplicationNotFound(false);
      } catch (error) {
        setApplicationNotFound(true);
        newMenuContext = {
          items: null,
          extras: null,
          categories: null,
        };
        setMenuContext(newMenuContext);
        localStorage.setItem("menuContext", JSON.stringify(newMenuContext));
      }
    };

    if (APPLICATION) {
      if (part1 !== "admin" && part1 !== "order") {
        fetchMenuData();
      }
    } else {
      setApplicationNotFound(true);
    }
  }, [APPLICATION, STORE_NAME]);

  useEffect(() => {
    if (!storeContext.name || !STORE_NAME || !STORE_NAME_CODE) {
      if (APPLICATION) {
        fetchStoreData();
      } else {
        setApplicationNotFound(true);
      }
    }
  }, []);

  const fetchStoreData = async () => {
    var newStoreContext;
    try {
      const response = await axios.get(`${BASE_API}api/Store/GetStoreDetails`, {
        headers: {
          Application: APPLICATION,
        },
      });
      const hoursResponse = await axios.get(
        `${BASE_API}api/Hours/GetStoreHours`,
        {
          headers: {
            Application: APPLICATION,
          },
        }
      );
      const deliveryHoursResponse = await axios.get(
        `${BASE_API}api/Hours/GetDeliveryHours`,
        {
          headers: {
            Application: APPLICATION,
          },
        }
      );
      const closureResponse = await axios.get(
        `${BASE_API}api/Hours/GetStoreClosures`,
        {
          headers: {
            Application: APPLICATION,
          },
        }
      );
      const etaResponse = await axios.get(
        `${BASE_API}api/Order/GetOrderETAs/1`,
        {
          headers: {
            Application: APPLICATION,
          },
        }
      );
      var isOpen = checkIfStoreIsOpen(hoursResponse.data, closureResponse.data);
      var isDeliveryOpen = checkIfStoreDeliveryIsOpen(
        deliveryHoursResponse.data,
        closureResponse.data
      );
      var pickupETA =
        etaResponse.data[0].pickupMin +
        " - " +
        etaResponse.data[0].pickupMax +
        " minutes";
      var deliveryETA =
        etaResponse.data[0].deliveryMin +
        " - " +
        etaResponse.data[0].deliveryMax +
        " minutes";
      newStoreContext = {
        open: isOpen,
        deliveryOpen: isDeliveryOpen,
        storeID: response.data[0].storeID,
        name: response.data[0].name,
        nameCode: response.data[0].nameCode,
        taxPercentage: response.data[0].taxPercentage,
        chargePercent: response.data[0].discountChargePercent,
        discountPercent: response.data[0].discountPercent,
        hours: hoursResponse.data,
        deliveryHours: deliveryHoursResponse.data,
        deliveryETA: deliveryETA,
        pickupETA: pickupETA,
        address: response.data[0].address,
        phoneNumber: response.data[0].phoneNumber,
        categoryName: response.data[0].categoryName,
        subCategoryName: response.data[0].subCategoryName,
        mxMerchant: response.data[0].mxMerchant,
        isActive: response.data[0].isActive,
        acceptDelivery: response.data[0].acceptDelivery,
        deliveryFee: response.data[0].deliveryFee,
        deliveryMiles: response.data[0].deliveryMiles,
        deliveryMin: response.data[0].deliveryMin,
        orderMin: response.data[0].orderMin,
        isCashAdjustment: response.data[0].isCashAdjustment,
        color: response.data[0].color,
        rewardsProgram: response.data[0].rewardsProgram,
        storeClosures: closureResponse.data,
      };
      if (!menuName) {
        setMenuName(response.data[0].menuName);
      }
      setCategoryName(response.data[0].categoryName);
      setSubCategoryName(response.data[0].subCategoryName);
      setDiscountEndDate(response.data[0].discountEndDate);
      setStoreContext(newStoreContext);
      localStorage.setItem("storeContext", JSON.stringify(newStoreContext));

      setStore(
        newStoreContext.hours,
        newStoreContext.open,
        newStoreContext.name,
        newStoreContext.nameCode,
        newStoreContext.taxPercentage,
        newStoreContext.discountPercent,
        newStoreContext.categoryName,
        newStoreContext.subCategoryName
      );
      setApplicationNotFound(false);
      setDetailsAreLoading(false);
    } catch (error) {
      setApplicationNotFound(true);
      newStoreContext = {
        hours: null,
        open: null,
        storeID: null,
        name: null,
        nameCode: null,
        taxPercentage: null,
        chargePercent: null,
        discountPercent: null,
        menuName: null,
        categoryName: null,
        subCategoryName: null,
        deliveryETA: null,
        pickupETA: null,
        address: null,
        phonenumber: null,
        mxMerchant: null,
        deliveryMiles: null,
        deliveryMin: null,
        orderMin: null,
        isCashAdjustment: null,
        color: null,
      };
      setStoreContext(newStoreContext);
      localStorage.setItem("storeContext", JSON.stringify(newStoreContext));
    }
  };

  useEffect(() => {
    var isOpen = checkIfStoreIsOpen();
    var isDeliveryOpen = checkIfStoreDeliveryIsOpen();
    var newStore = storeContext;
    newStore.open = isOpen;
    newStore.deliveryOpen = isDeliveryOpen;
    setStore(newStore);
    localStorage.setItem("storeContext", JSON.stringify(newStore));
  }, []);

  useEffect(() => {
    async function fetchCartData() {
      try {
        const response = await axios.get(`${BASE_API}api/Cart/GetMyCart`, {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            Application: APPLICATION,
          },
        });
        if (response.data.Cart.length < 1) {
          return;
        }
        var results = formatUsersCart(response);
        var newAppContext = {
          userInfo: appContext.userInfo,
          cart: results.loaded_cart,
          cartItems: results.loaded_cartItems,
        };
        setAppContext(newAppContext);
        localStorage.setItem("appContext", JSON.stringify(newAppContext));
      } catch (error) {
        if (error.response.status === 401) {
          newAppContext = {
            userInfo: null,
            cart: null,
            cartItems: null,
          };
          setAppContext(newAppContext);
          localStorage.setItem("appContext", JSON.stringify(newAppContext));
          toast.error("Your session has expired please login again.");
        }
      }
    }

    if (appContext.userInfo && !appContext.cart) {
      if (
        APPLICATION &&
        (appContext.userInfo.role === "User" ||
          appContext.userInfo.role === "Admin")
      ) {
        fetchCartData();
      }
    }
  }, [appContext]);

  useEffect(() => {
    if (STORE_NAME_CODE && appContext.userInfo) {
      if (STORE_NAME_CODE !== appContext.userInfo.storeName) {
        var newAppContext = {
          userInfo: null,
          cart: null,
          cartItems: null,
        };
        setAppContext(newAppContext);
        localStorage.setItem("appContext", JSON.stringify(newAppContext));
      }
    }
  }, [STORE_NAME_CODE, appContext.userInfo]);

  return (
    <>
      {STORE_NAME_CODE &&
        storeContext.isActive !== "true" &&
        !showAuthenticateModal && (
          <div className="store_is_not_active fixed-top d-flex justify-content-center">
            Sorry we are closed right now. Please come back soon and try again.
          </div>
        )}
      {showAuthenticateModal && (
        <AuthenticateModal
          appContext={appContext}
          showAuthenticateModal={showAuthenticateModal}
          setShowAuthenticateModal={setShowAuthenticateModal}
          authKey={authKey}
          setAuthKey={setAuthKey}
          setAppContext={setAppContext}
        />
      )}
      {applicationNotFound ? (
        <div className="app not_found">
          <div className="fs-1">Oops!</div>
          <div>The Store You're Looking For Is Not Here!</div>
          <div>Please Make Sure You Are Accessing The Correct Link.</div>
        </div>
      ) : (
        <>
          {detailsAreLoading ? (
            <div className="loading_details" id="loading_details">
              <LoadingLogo />
            </div>
          ) : (
            <StoreContext.Provider value={storeContext}>
              <ApplicationContext.Provider value={appContext}>
                <MenuContext.Provider value={menuContext}>
                  <div className="app">
                    <ToastContainer
                      position="bottom-center"
                      limit={1}
                      {...toastProps}
                    />
                    <BrowserRouter>
                      <Routes>
                        <Route
                          path="/"
                          element={
                            <Home
                              showItemModal={showItemModal}
                              setShowItemModal={setShowItemModal}
                              selectedItem={selectedItem}
                              setSelectedItem={setSelectedItem}
                              detailsAreLoading={detailsAreLoading}
                              menuName={menuName}
                              setMenuName={setMenuName}
                              categoryName={categoryName}
                              setCategoryName={setCategoryName}
                              subCategoryName={subCategoryName}
                              setSubCategoryName={setSubCategoryName}
                              discountEndDate={discountEndDate}
                              setAppContext={setAppContext}
                              authKey={authKey}
                              setAuthKey={setAuthKey}
                              showAuthenticateModal={showAuthenticateModal}
                              setShowAuthenticateModal={
                                setShowAuthenticateModal
                              }
                              cartOpen={cartOpen}
                              setCartOpen={setCartOpen}
                              showEditItemModal={showEditItemModal}
                              setShowEditItemModal={setShowEditItemModal}
                              isDisabled={isDisabled}
                              setIsDisabled={setIsDisabled}
                            />
                          }
                        />
                        <Route
                          path="/terms"
                          element={
                            <Terms
                              authKey={authKey}
                              setAuthKey={setAuthKey}
                              setShowAuthenticateModal={
                                setShowAuthenticateModal
                              }
                              setAppContext={setAppContext}
                            />
                          }
                        />
                        <Route
                          path="/privacy"
                          element={
                            <Privacy
                              authKey={authKey}
                              setAuthKey={setAuthKey}
                              setShowAuthenticateModal={
                                setShowAuthenticateModal
                              }
                              setAppContext={setAppContext}
                            />
                          }
                        />
                        <Route
                          path="/refund"
                          element={
                            <Refund
                              authKey={authKey}
                              setAuthKey={setAuthKey}
                              setShowAuthenticateModal={
                                setShowAuthenticateModal
                              }
                              setAppContext={setAppContext}
                            />
                          }
                        />
                        <Route
                          path="/order"
                          element={
                            <Order
                              setAuthKey={setAuthKey}
                              setAppContext={setAppContext}
                              selectedAddress={selectedAddress}
                              setSelectedAddress={setSelectedAddress}
                              discountEndDate={discountEndDate}
                              setShowAuthenticateModal={
                                setShowAuthenticateModal
                              }
                            />
                          }
                        />
                        <Route
                          path="/completion"
                          element={
                            <OrderComplete
                              setAuthKey={setAuthKey}
                              setAppContext={setAppContext}
                              setShowAuthenticateModal={
                                setShowAuthenticateModal
                              }
                            />
                          }
                        />
                        <Route
                          path="/account"
                          element={
                            <Account
                              setAuthKey={setAuthKey}
                              setAppContext={setAppContext}
                              setShowAuthenticateModal={
                                setShowAuthenticateModal
                              }
                            />
                          }
                        />
                        <Route
                          path="/rewards"
                          element={
                            <Rewards
                              isDisabled={isDisabled}
                              setIsDisabled={setIsDisabled}
                              setShowEditItemModal={setShowEditItemModal}
                              showItemModal={showItemModal}
                              setShowItemModal={setShowItemModal}
                              selectedItem={selectedItem}
                              setSelectedItem={setSelectedItem}
                              setAuthKey={setAuthKey}
                              setAppContext={setAppContext}
                              setShowAuthenticateModal={
                                setShowAuthenticateModal
                              }
                              cartOpen={cartOpen}
                              setCartOpen={setCartOpen}
                              detailsAreLoading={detailsAreLoading}
                            />
                          }
                        />
                        {appContext.userInfo &&
                        appContext.userInfo.role === "Admin" ? (
                          <>
                            <Route
                              path="/admin"
                              element={
                                <Admin
                                  setAuthKey={setAuthKey}
                                  setAppContext={setAppContext}
                                  setStoreContext={setStoreContext}
                                  setShowAuthenticateModal={
                                    setShowAuthenticateModal
                                  }
                                />
                              }
                            />
                          </>
                        ) : (
                          <>
                            <Route
                              path="/admin"
                              element={
                                <NotAuthorized
                                  setAuthKey={setAuthKey}
                                  setAppContext={setAppContext}
                                  setShowAuthenticateModal={
                                    setShowAuthenticateModal
                                  }
                                />
                              }
                            />
                          </>
                        )}
                      </Routes>
                    </BrowserRouter>
                  </div>
                </MenuContext.Provider>
              </ApplicationContext.Provider>
            </StoreContext.Provider>
          )}
        </>
      )}
    </>
  );
}

export default App;
