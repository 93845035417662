import React from "react";

function MxAddPaymentMethod(props) {
  function formatCardNumber(e) {
    var formattedCardNumber = e.target.value.replace(/ /g, "");
    props.setCardNumber(formattedCardNumber);
  }

  return (
    <div className="AddPaymentMethod">
      <div className="payment_inputs">
        <label>Card Number</label>
        <input
          placeholder={"1234123412341234"}
          onChange={(e) => formatCardNumber(e)}
        />

        {props.processor === "AuthorizeNet" && (
          <>
            <label>Expiration Date</label>
            <input
              placeholder={"MM/YY"}
              onChange={(e) => props.setExpDate(e.target.value)}
            />
          </>
        )}

        {props.processor === "MxMerchant" && (
          <div Style={"display: flex;"}>
            <div className="payment_inputs" Style={"margin-right: 0.5rem;"}>
              <label Style={"width: 90%;"}>Expiration Month</label>
              <input
                Style={"width: 90%;"}
                placeholder={"MM"}
                onChange={(e) => props.setExpMonth(e.target.value)}
              />
            </div>
            <div className="payment_inputs" Style={"margin-left: 0.5rem;"}>
              <label Style={"width: 90%;"}>Expiration Year</label>
              <input
                Style={"width: 90%;"}
                placeholder={"YYYY"}
                onChange={(e) => props.setExpYear(e.target.value)}
              />
            </div>
          </div>
        )}

        <label>CVC Code</label>
        <input
          placeholder={"123"}
          onChange={(e) => props.setCvcCode(e.target.value)}
        />

        <div Style={"margin: 0.5rem 0; border-bottom: 1px solid gray;"}>
          Billing Information
        </div>

        <label>Address</label>
        <input
          placeholder={"120 Main Street"}
          onChange={(e) => props.setAddress(e.target.value)}
        />
        <label>Apartment Number (optional)</label>
        <input
          placeholder={"2"}
          onChange={(e) => props.setApartmentNumber(e.target.value)}
        />
        <label>Zip Code</label>
        <input
          placeholder={"12345"}
          onChange={(e) => props.setZipCode(e.target.value)}
        />
      </div>
      <div className="disclosure">
        By providing your card information, you allow EWS to charge your card
        for future payments in accordance with their terms.
      </div>
    </div>
  );
}

export default MxAddPaymentMethod;
