import React, { useState, useContext } from "react";
import { HiMenu } from "react-icons/hi";
import "./navbar.scss";
import ApplicationContext from "../../ApplicationContext";
import {
  DISCOUNT_PERCENTAGE,
  HOME_REDIRECT,
  STORE_NAME,
  STORE_NAME_CODE,
} from "../../constants";
import StoreContext from "../../StoreContext";
import HoursModal from "../hours/HoursModal";
import LocationModal from "../location/LocationModal";

function Navbar(props) {
  const storeContext = useContext(StoreContext);
  const appContext = useContext(ApplicationContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showHoursModal, setShowHoursModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);

  async function dropdown() {
    setShowDropdown(!showDropdown);
  }

  async function showAuthModal(keyIn) {
    props.setAuthKey(keyIn);
    props.setShowAuthenticateModal(true);
  }

  function logoutHandler() {
    var newAppContext = {
      userInfo: null,
      cart: null,
      cartItems: null,
    };
    props.setAppContext(newAppContext);
    localStorage.setItem("appContext", JSON.stringify(newAppContext));
    setShowDropdown(false);
    window.location.replace(HOME_REDIRECT);
  }

  async function toggleHoursModal() {
    if (storeContext.hours) {
      setShowHoursModal(true);
    }
  }
  async function toggleLocationModal() {
    setShowLocationModal(true);
  }

  return (
    <>
      <header className="navbar">
        <div className="topbar">
          <div className="left">
            <a href={`/`}>{STORE_NAME}</a>
          </div>
          <div className="right">
            <HiMenu onClick={dropdown} />
            {showDropdown ? (
              <div className="dropdown">
                <ul className="container-fluid list-unstyled mb-2">
                  {appContext.userInfo &&
                    appContext.userInfo.role === "Admin" && (
                      <a
                        className="nav_item py-3 border-bottom"
                        href={`/admin`}
                      >
                        Admin Portal
                      </a>
                    )}
                  <li
                    className="nav_item py-3 border-bottom"
                    onClick={() => toggleHoursModal()}
                  >
                    Hours
                  </li>
                  {appContext.userInfo &&
                  (appContext.userInfo.role === "User" ||
                    appContext.userInfo.role === "Admin") ? (
                    <>
                      <a
                        className="nav_item py-3 border-bottom"
                        href={`/account`}
                      >
                        Account
                      </a>
                      {storeContext.rewardsProgram === "true" && (
                        <a
                          className="nav_item py-3 border-bottom"
                          href={`/rewards`}
                        >
                          Rewards
                        </a>
                      )}
                      <li
                        className="nav_item py-3 border-bottom"
                        onClick={logoutHandler}
                      >
                        Logout
                      </li>
                    </>
                  ) : (
                    <>
                      <li
                        className="nav_item py-3 border-bottom"
                        onClick={() => showAuthModal("Login")}
                      >
                        Login
                      </li>
                      <li
                        className="nav_item py-3 border-bottom"
                        onClick={() => showAuthModal("Register")}
                      >
                        Register
                      </li>
                    </>
                  )}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
        {props.showWelcome && (
          <div className="welcome w-100 d-flex flex-column justify-content-center py-2">
            <div className="welcome_image_container d-flex justify-content-center">
              <img
                className="image mb-1"
                src={`./pictures/${STORE_NAME_CODE}.png`}
                alt=""
              />
            </div>
            <div className="card address_container d-flex flex-column p-2 mb-2">
              {storeContext.address}
            </div>
            {DISCOUNT_PERCENTAGE && (
              <div className="card discount d-flex flex-column p-2">
                <div className="d-flex justify-content-center message">
                  🎉{" "}
                  <b className="mx-1">
                    Enjoy {DISCOUNT_PERCENTAGE} Off All Orders
                  </b>{" "}
                  🎉
                </div>
                {props.discountEndDate && (
                  <div className="d-flex justify-content-center endDate">
                    Offer Ends: <b className="mx-1"> {props.discountEndDate}</b>
                  </div>
                )}
                <div className="d-flex justify-content-center endDate">
                  Discount Applied at Checkout
                </div>
              </div>
            )}
          </div>
        )}
      </header>
      {showHoursModal ? (
        <HoursModal
          showHoursModal={showHoursModal}
          setShowHoursModal={setShowHoursModal}
        />
      ) : null}
      {showLocationModal ? (
        <LocationModal
          showLocationModal={showLocationModal}
          setShowLocationModal={setShowLocationModal}
        />
      ) : null}
    </>
  );
}

export default Navbar;
