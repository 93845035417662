import React, { useContext, useState, useEffect } from "react";
import ApplicationContext from "../../ApplicationContext";
import StoreContext from "../../StoreContext";
import { AiFillPlusCircle } from "react-icons/ai";
import AddPaymentMethod from "./AddPaymentMethod";
import { toast } from "react-toastify";
import axios from "axios";
import {
  APPLICATION,
  BASE_API,
  ORDER_FEE,
  STORE_NAME_CODE,
} from "../../constants";
import { CreateGuid } from "../../utils/authenticate/CreateGUID";
import { useNavigate } from "react-router-dom";
import { getNowTime } from "../../utils/time/GetNowTime";

function CheckoutForm(props) {
  const navigate = useNavigate();
  const appContext = useContext(ApplicationContext);
  const storeContext = useContext(StoreContext);

  const [isAddingPaymentMethod, setIsAddingPaymentMethod] = useState(true);
  const [selectedPayment, setSelectedPayment] = useState();
  const [previouslyActivePayment, setPreviouslyActivePayment] = useState();

  const [cardNumber, setCardNumber] = useState();
  const [expDate, setExpDate] = useState();
  const [cvcCode, setCvcCode] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [billingState, setBillingState] = useState();
  const [billingCity, setBillingCity] = useState();
  const [address, setAddress] = useState();
  const [apartmentNumber, setApartmentNumber] = useState();
  const [zipCode, setZipCode] = useState();

  const newOrderId = CreateGuid();
  let orderData = {
    Items: [],
  };

  useEffect(() => {
    var discountedTax = null;
    var discountedSubtotal = null;
    var discountedTotal = null;
    var tax = parseFloat(props.taxAmount);

    if (
      props.discountedSubtotal &&
      props.discountedTax &&
      props.discountedTotal
    ) {
      discountedSubtotal = props.discountedSubtotal;
      discountedTax = props.discountedTax;
      discountedTotal = props.discountedTotal;
    }
    orderData.OrderID = newOrderId;
    orderData.OrderStatusID = 1;
    orderData.DiscountedSubtotal = discountedSubtotal;
    orderData.DiscountedTax = discountedTax;
    orderData.DiscountedTotal = discountedTotal;
    orderData.Fee = ORDER_FEE;
    orderData.PhoneNumber = appContext.userInfo.phoneNumber;
    orderData.Email = appContext.userInfo.email;
    orderData.DeliveryFee = props.deliveryFee;
    orderData.OrderSubtotal = appContext.cart.CartPrice;
    orderData.OrderTax = tax;

    for (var a = 0; a < appContext.cartItems.length; a++) {
      var newCartItemID = CreateGuid();
      orderData.Items[a] = {
        OrderItemID: newCartItemID,
        OrderItem_MenuItemID: appContext.cartItems[a].CartItem_MenuItemID,
        ItemQuantity: appContext.cartItems[a].ItemQuantity,
        ItemTotalPrice: appContext.cartItems[a].ItemTotalPrice,
        ItemSpecialRequests: appContext.cartItems[a].ItemSpecialRequests,
        ItemName: appContext.cartItems[a].ItemName,
        ItemPrice: appContext.cartItems[a].ItemPrice,
        SizeCode: appContext.cartItems[a].SizeCode,
        Extras: [],
      };
      if (!orderData.Items[a].SizeCode) {
        orderData.Items[a].SizeCode = null;
      }

      for (var b = 0; b < appContext.cartItems[a].Extras.length; b++) {
        if (appContext.cartItems[a].Extras[b].PizzaFirstHalf === null) {
          appContext.cartItems[a].Extras[b].PizzaFirstHalf = 0;
        }
        if (appContext.cartItems[a].Extras[b].PizzaSecondHalf === null) {
          appContext.cartItems[a].Extras[b].PizzaSecondHalf = 0;
        }
        orderData.Items[a].Extras[b] = {
          OrderExtra_OrderItemID: newCartItemID,
          OrderExtra_MenuItemExtraID:
            appContext.cartItems[a].Extras[b].CartExtra_MenuItemExtraID,
          PizzaFirstHalf: appContext.cartItems[a].Extras[b].PizzaFirstHalf,
          PizzaSecondHalf: appContext.cartItems[a].Extras[b].PizzaSecondHalf,
          PizzaHalfCode: appContext.cartItems[a].Extras[b].PizzaHalfCode,
          OrderExtraName: appContext.cartItems[a].Extras[b].ExtraName,
          ExtraPrice: appContext.cartItems[a].Extras[b].ExtraPrice,
        };
      }
    }
  }, [newOrderId]);

  useEffect(() => {
    if (props.customerPaymentProfiles.length === 1 && isAddingPaymentMethod) {
      setSelectedPayment(props.customerPaymentProfiles[0]);
      setIsAddingPaymentMethod(false);
    } else if (
      props.customerPaymentProfiles.length === 1 &&
      !isAddingPaymentMethod
    ) {
      setSelectedPayment(props.customerPaymentProfiles[0]);
    } else if (props.customerPaymentProfiles.length > 1) {
      setIsAddingPaymentMethod(false);
    } else {
      setSelectedPayment();
    }
  }, [props]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (document.getElementById("submit")) {
      //PREVENT Double click on submit code
      document.getElementById("submit").disabled = true;
      document.getElementById("payment_method").display = false;
      document.getElementById("button-text").innerText = "PROCESSING...";

      // Use setTimeout to change innerText back to "CHECKOUT" after 5 seconds
      setTimeout(function () {
        document.getElementById("submit").disabled = false;
        document.getElementById("button-text").innerText = "COMPLETE CHECKOUT";
      }, 3000); // 3000 milliseconds = 3 seconds
    }

    if (storeContext.isActive !== "true") {
      toast.error(
        "Sorry we are closed right now. Please come back soon and try again."
      );
      return;
    }
    if (!orderData.Items) {
      return;
    }
    if (props.orderType === "Delivery") {
      if (orderData.OrderTotal < storeContext.deliveryMin) {
        toast.error(
          `Minimum Order amount for Delivery is $${storeContext.deliveryMin}. Please adjust your order and try again.`
        );
        return;
      }
      if (!props.selectedAddress) {
        toast.error("Please select an address for Delivery orders!");
        return;
      }
    }
    if (props.orderType === "Pickup") {
      if (storeContext.open !== "Open" && props.nowOrLater !== "Later") {
        toast.error(
          "We are not open for Pickup Orders right now. Please select a time for a Later Order."
        );
        return;
      }
    }
    if (props.orderType === "Delivery") {
      if (
        storeContext.deliveryOpen !== "Open" &&
        props.nowOrLater !== "Later"
      ) {
        toast.error(
          "We are not open for Delivery right now. Please select a time for a Later Order."
        );
        return;
      }
    }
    if (!props.orderType) {
      toast.error("Please select either Pickup or Delivery");
      return;
    }

    var orderETA;
    //if payment is successful
    if (props.orderType === "Pickup") {
      orderETA = storeContext.pickupETA;
    } else if (props.orderType === "Delivery") {
      orderETA = storeContext.deliveryETA;
    } else {
      toast.error("Order Type error: Please reload the page and try again.");
      return;
    }

    if (
      props.orderType === "Delivery" &&
      (!props.selectedAddress.streetAddress ||
        !props.selectedAddress.residenceID ||
        !props.selectedAddress.city ||
        !props.selectedAddress.stateName ||
        !props.selectedAddress.zipCode)
    ) {
      toast.error("Order Address error: Please reload the page and try again.");
      return;
    }

    if (cardNumber && expDate && cvcCode && zipCode) {
      //if card details are entered charge the card
      var authNetPaymentResult;
      var profileId;
      var paymentProfileId;
      var transactionId;

      try {
        authNetPaymentResult = await authorizeAuthorizeNetPayment();
        var errorData = {
          OrderID: newOrderId,
          Message:
            appContext.userInfo.email +
            " " +
            appContext.userInfo.role +
            " " +
            authNetPaymentResult.data.data.transactionResponse.responseCode,
        };
        await axios.post(`${BASE_API}api/TraceLog/AddTracelog`, errorData, {
          headers: {
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        });
      } catch (error) {
        console.error("Error in payment authorization:", error);
      } //IF transaction is successful send order details to database (this is handled by handleCheckout)

      //IF transaction is successful send order details to database (this is handled by handleCheckout)
      if (
        authNetPaymentResult.data.data.transactionResponse.responseCode === "1"
      ) {
        await handleCheckout(orderETA);
      } else {
        //else payment is NOT successful
        if (
          authNetPaymentResult.data.data.transactionResponse.errors[0]
            .errorCode === "27"
        ) {
          toast.error(
            "❗Uh oh! It seems there's an issue with your billing address. Your bank may have temporarily authorized the transaction, but don't worry, we won't actually charge you. Please double-check and re-enter your billing information to complete your order",
            {
              autoClose: false,
              closeOnClick: true,
              closeButton: true,
            }
          );
        } else {
          toast.error(
            authNetPaymentResult.data.data.transactionResponse.errors[0]
              .errorText
          );
        }
        return;
      }

      if (appContext.userInfo.customerID) {
        //if they ARE a CUSTOMER already in auth net (so we do NOT createNewAuthNetCustomer)
        var createPaymentProfileResult =
          await createNewAuthNetCustomerPaymentProfile();
        profileId = appContext.userInfo.customerID;
        paymentProfileId = createPaymentProfileResult.PaymentProfileID;
        transactionId = authNetPaymentResult.TransactionID;

        var newAppContext = {
          userInfo: appContext.userInfo,
          cart: null,
          cartItems: null,
        };
        props.setAppContext(newAppContext);
        localStorage.setItem("appContext", JSON.stringify(newAppContext));
      } else {
        //else they are NOT a CUSTOMER in auth net yet (so we do createNewAuthNetCustomer)
        var newAuthNetCustomerResult = await createNewAuthNetCustomer();
        profileId = newAuthNetCustomerResult.ProfileID;
        paymentProfileId = newAuthNetCustomerResult.PaymentProfileID;
        transactionId = authNetPaymentResult.TransactionID;
      }
      await updateOrderWithAuthNetInfo(
        newOrderId,
        transactionId,
        profileId,
        paymentProfileId
      );
    } else if (selectedPayment) {
      //else the card details are NOT entered charge the selected payment method
      try {
        var chargePaymentProfileResult = await chargeCustomerByPaymentProfile(
          appContext.userInfo.customerID,
          selectedPayment.customerPaymentProfileId
        );
        var data = {
          OrderID: newOrderId,
          Message:
            appContext.userInfo.email +
            " " +
            appContext.userInfo.role +
            " " +
            chargePaymentProfileResult.Res.responseCode,
        };
        await axios.post(`${BASE_API}api/TraceLog/AddTracelog`, data, {
          headers: {
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        });
      } catch (error) {
        console.error("Error in vaulted payment authorization:", error);
      } //IF transaction is successful send order details to database (this is handled by handleCheckout)

      transactionId = chargePaymentProfileResult.TransactionID;
      profileId = chargePaymentProfileResult.ProfileID;
      paymentProfileId = chargePaymentProfileResult.PaymentProfileID;

      if (chargePaymentProfileResult.Res.responseCode === "1") {
        await handleCheckout(orderETA);
      } else {
        toast.error(chargePaymentProfileResult.Res.errors[0].errorText);
        return;
      }
      await updateOrderWithAuthNetInfo(
        newOrderId,
        transactionId,
        profileId,
        paymentProfileId
      );
      var newContext = {
        userInfo: appContext.userInfo,
        cart: null,
        cartItems: null,
      };
      props.setAppContext(newContext);
      localStorage.setItem("appContext", JSON.stringify(newContext));
    } else {
      toast.error(
        "Please fill out all payment details proceeding to checkout!"
      );
    }
  };

  async function chargeCustomerByPaymentProfile(profileId, paymentProfileId) {
    try {
      var amount;
      if (storeContext.chargePercent !== null) {
        amount = props.discountedTotal;
      } else {
        amount = props.finalTotal;
      }
      var chargeProfile = {
        Amount: amount,
        ProfileID: profileId,
        PaymentProfileID: paymentProfileId,
      };
      const dataresp = await axios.post(
        `${BASE_API}api/AuthorizeNet/ChargeCustomerPaymentProfile`,
        chargeProfile,
        {
          headers: {
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        }
      );

      return {
        TransactionID: dataresp.data.transactionResponse.transId,
        ProfileID: dataresp.data.transactionResponse.profile.customerProfileId,
        PaymentProfileID:
          dataresp.data.transactionResponse.profile.customerPaymentProfileId,
        Res: dataresp.data.transactionResponse,
      };
    } catch (err) {
      toast.error("Failed to charge customer payment profile.");
    }
  }

  async function updateOrderWithAuthNetInfo(
    newOrderId,
    transactionId,
    profileId,
    paymentProfileId
  ) {
    var authNetData = {
      OrderID: newOrderId,
      CustomerID: profileId,
      TransactionID: transactionId,
      PaymentProfileID: paymentProfileId,
    };
    authNetData = JSON.stringify(authNetData);
    await axios.put(
      `${BASE_API}api/Order/UpdateOrdersAuthNetDetails`,
      authNetData,
      {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      }
    );
  }

  async function handleCheckout(orderETA) {
    var pointsEarnedThisOrder;
    var newPointsBalance;
    var userPointsData = {};
    var newContext = {};
    var newUserInfo = {};
    var newAppContext = {};
    var total;
    try {
      var utcISOString = getNowTime();

      orderData.OrderType = props.orderType;
      orderData.OrderTip = props.tipAmount.amount;
      orderData.OrderedDate = utcISOString;
      orderData.OrderETA = orderETA;
      orderData.DeliveryFee = props.deliveryFee;
      orderData.PercentageFee = props.isCashAdjustment;
      if (props.nowOrLater === "Later") {
        const utcDate = props.selectedDay.mysqlDate.split("T")[0];
        const utcTime = props.selectedTime.est;
        orderData.RequestedDate = utcDate + "T" + utcTime;
      } else {
        orderData.RequestedDate = utcISOString;
      }
      if (storeContext.chargePercent) {
        orderData.DiscountedChargePercent = storeContext.chargePercent;
      }

      if (props.orderType === "Delivery") {
        orderData.OrderAddress = props.selectedAddress.residenceID;
        orderData.StreetAddress =
          props.selectedAddress.streetAddress +
          ", " +
          props.selectedAddress.city +
          " " +
          props.selectedAddress.stateName +
          " " +
          props.selectedAddress.zipCode;
      }
      if (
        appContext.userInfo.role === "GuestUser" ||
        appContext.userInfo.role === "Guest"
      ) {
        orderData.Name = appContext.userInfo.firstName;
      } else if (
        appContext.userInfo.role === "Admin" ||
        appContext.userInfo.role === "User"
      ) {
        orderData.Name =
          appContext.userInfo.firstName + " " + appContext.userInfo.lastName;
      } else {
        orderData.Name = "-";
      }

      var orderDataJson = JSON.stringify(orderData);
      await axios.post(`${BASE_API}api/Order/NewOrder`, orderDataJson, {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      });
      toast.success("Order Success");

      //if the store has a rewards program
      if (storeContext.rewardsProgram === "true") {
        //if it is a user update their reward points
        if (
          appContext.userInfo.role === "User" ||
          appContext.userInfo.role === "Admin"
        ) {
          if (orderData.DiscountedTotal) {
            pointsEarnedThisOrder = parseFloat(
              orderData.DiscountedTotal.toFixed(0)
            );
          } else {
            total =
              orderData.OrderSubtotal +
              orderData.OrderTax +
              orderData.OrderTip +
              orderData.Fee +
              orderData.PercentageFee;
            if (props.orderType === "Delivery") {
              total = total + orderData.DeliveryFee;
            }
            total = parseFloat(total.toFixed(0));
            pointsEarnedThisOrder = total;
          }

          newPointsBalance =
            appContext.userInfo.rewardPoints + pointsEarnedThisOrder;
          userPointsData = {
            rewardPoints: newPointsBalance,
            userID: appContext.userInfo.userID,
          };
          await axios.put(
            `${BASE_API}UpdateCustomerRewardsPoints`,
            userPointsData,
            {
              headers: {
                Authorization: `Bearer ${appContext.userInfo.token}`,
                Application: APPLICATION,
              },
            }
          );

          newUserInfo = appContext.userInfo;
          newUserInfo.rewardPoints = parseFloat(newPointsBalance);

          newAppContext = {
            userInfo: newUserInfo,
            cart: null,
            cartItems: null,
          };
          props.setAppContext(newAppContext);
          localStorage.setItem("appContext", JSON.stringify(newAppContext));
        } else {
          newContext = {
            userInfo: appContext.userInfo,
            cart: null,
            cartItems: null,
          };
          props.setAppContext(newContext);
          localStorage.setItem("appContext", JSON.stringify(newContext));
        }
      } else {
        newContext = {
          userInfo: appContext.userInfo,
          cart: null,
          cartItems: null,
        };
        props.setAppContext(newContext);
        localStorage.setItem("appContext", JSON.stringify(newContext));
      }
      
      navigate(`/completion`);
    } catch (error) {
      var d = JSON.stringify(orderData);
      var data = {
        OrderID: newOrderId,
        Message: JSON.stringify(orderData),
      };
      await axios.post(`${BASE_API}api/TraceLog/AddTracelog`, data, {
        headers: {
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      });
      
      await axios.post(`${BASE_API}api/Order/NewOrder`, d, {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      });
    }
  }

  async function authorizeAuthorizeNetPayment() {
    try {
      var authNetData = {
        CardNumber: cardNumber,
        ExpirationDate: expDate,
        CardCode: cvcCode,
        FirstName: firstName,
        LastName: lastName,
        City: billingCity,
        State: billingState,
        Address: address,
        ZipCode: zipCode,
        ShippingCity: null,
        ShippingState: null,
        ShippingAddress: null,
        ShippingZipCode: null,
        Amount: props.finalTotal,
        Email: appContext.userInfo.email,
      };
      if (storeContext.chargePercent) {
        authNetData.Amount = props.discountedTotal;
      } else {
        authNetData.Amount = props.finalTotal;
      }
      if (props.orderType === "Delivery") {
        authNetData.ShippingCity = props.selectedAddress.city;
        authNetData.ShippingState = props.selectedAddress.stateName;
        authNetData.ShippingAddress = props.selectedAddress.streetAddress;
        authNetData.ShippingZipCode = props.selectedAddress.zipCode;
      }
      const dataresp = await axios.post(
        `${BASE_API}api/AuthorizeNet/authorize-authorizenet-payment`,
        JSON.stringify(authNetData),
        {
          headers: {
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        }
      );
      if (!dataresp.data) {
        toast.error("Your card details were not valid. Please try again.");
      }
      return {
        data: dataresp,
        TransactionID: dataresp.data.transactionResponse.transId,
      };
    } catch (err) {
      toast.error(err);
    }
  }

  async function createNewAuthNetCustomerPaymentProfile() {
    var authorizeNetCustomer = {
      cardNumber: cardNumber,
      expirationDate: expDate,
      cardCode: cvcCode,
      firstName: appContext.userInfo.firstName,
      lastName: appContext.userInfo.lastName,
      city: billingCity,
      state: billingState,
      address: address,
      zipCode: zipCode,
      email: "",
    };
    authorizeNetCustomer.email = appContext.userInfo.email;
    authorizeNetCustomer.customerProfileId = appContext.userInfo.customerID;

    var newData = JSON.stringify(authorizeNetCustomer);
    try {
      const dataresp = await axios.post(
        `${BASE_API}api/AuthorizeNet/CreateCustomerPaymentProfile`,
        newData,
        {
          headers: {
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        }
      );
      return {
        PaymentProfileID: dataresp.data.customerPaymentProfileId,
        ProfileID: dataresp.data.customerProfileId,
      };
    } catch (err) {
      toast.error("Failed to create Customer ID");
    }
  }

  async function createNewAuthNetCustomer() {
    var authorizeNetCustomer = {
      cardNumber: cardNumber,
      expirationDate: expDate,
      cardCode: cvcCode,
      city: billingCity,
      state: billingState,
      address: address,
      email: appContext.userInfo.email,
      zipCode: zipCode,
      merchantCustomerID: "",
    };
    authorizeNetCustomer.merchantCustomerID = appContext.userInfo.userID;
    if (
      appContext.userInfo.role === "GuestUser" ||
      appContext.userInfo.role === "Guest"
    ) {
      authorizeNetCustomer.firstName = appContext.userInfo.firstName;
    } else {
      authorizeNetCustomer.firstName = appContext.userInfo.firstName;
      authorizeNetCustomer.lastName = appContext.userInfo.lastName;
    }

    var newData = JSON.stringify(authorizeNetCustomer);
    try {
      const dataresp = await axios.post(
        `${BASE_API}api/AuthorizeNet/CreateNewAuthorizeNetCustomer`,
        newData,
        {
          headers: {
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        }
      );
      updateCustomerID(dataresp.data.customerProfileId);

      return {
        PaymentProfileID: dataresp.data.customerPaymentProfileIdList[0],
        ProfileID: dataresp.data.customerProfileId,
      };
    } catch (err) {
      toast.error("Failed to create Customer ID");
    }
  }

  async function updateCustomerID(profileID) {
    try {
      var newUser;
      if (appContext.userInfo.role === "GuestUser") {
        newUser = appContext.userInfo;
        newUser.customerID = profileID;
      } else {
        newUser = appContext.userInfo;
        newUser.customerID = profileID;
        await axios.put(`${BASE_API}api/User/UpdateCustomerID`, profileID, {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        });
      }
      var newAppContext = {
        userInfo: newUser,
        cart: null,
        cartItems: null,
      };
      props.setAppContext(newAppContext);
      localStorage.setItem("appContext", JSON.stringify(newAppContext));
    } catch (err) {
      toast.error("Failed to create Customer ID");
    }
  }

  async function addPaymentMethod() {
    if (isAddingPaymentMethod === true) {
      if (previouslyActivePayment) {
        setSelectedPayment(previouslyActivePayment);
        var profileSelected = document.querySelector(
          `.payment_profile_${previouslyActivePayment.customerPaymentProfileId}`
        );
        if (profileSelected) {
          profileSelected.classList.add("Active");
        }
      }
    } else {
      var activeProfile = document.querySelector(".payment_profile.Active");
      if (activeProfile) {
        activeProfile.classList.remove("Active");
      }
      if (props.customerPaymentProfiles.length === 1) {
        setPreviouslyActivePayment(props.customerPaymentProfiles[0]);
      } else if (props.customerPaymentProfiles.length > 1) {
        setPreviouslyActivePayment(selectedPayment);
      }
      setSelectedPayment();
    }
    setIsAddingPaymentMethod(!isAddingPaymentMethod);
  }

  async function setPaymentProfileForOrder(i) {
    if (!isAddingPaymentMethod) {
      var activeProfile = document.querySelector(".payment_profile.Active");
      if (activeProfile) {
        activeProfile.classList.remove("Active");
        setSelectedPayment();
      } else {
        var profileSelected = document.getElementById(
          `payment_profile_${i.customerPaymentProfileId}`
        );
        profileSelected.classList.add("Active");
        setSelectedPayment(i);
      }
    } else {
      toast.error(
        "Please close the add a new payment method to select a saved payment method."
      );
    }
  }

  async function invalidItemsSubmit() {
    toast.error(
      "You have items that are invalid to order at the selected time. Please check your cart and try again!"
    );
  }

  return (
    <div className="card m-1 checkout" id="payment_method">
      <div className="card-header">
        <div className="orderPage_header">Choose a payment method</div>
      </div>
      <div className="card-body">
        <div className="add_payment_method" onClick={addPaymentMethod}>
          <div className="add_paymentMethodTitle">Add new payment method</div>
          <AiFillPlusCircle />
        </div>

        {isAddingPaymentMethod && props.customerPaymentProfiles.length > 0 && (
          <form>
            <AddPaymentMethod
              setCardNumber={setCardNumber}
              setExpDate={setExpDate}
              setCvcCode={setCvcCode}
              setAddress={setAddress}
              setApartmentNumber={setApartmentNumber}
              setZipCode={setZipCode}
              processor={"AuthorizeNet"}
            />
          </form>
        )}
        {props.customerPaymentProfiles.length === 0 ? (
          <form>
            <AddPaymentMethod
              setCardNumber={setCardNumber}
              setExpDate={setExpDate}
              setCvcCode={setCvcCode}
              setAddress={setAddress}
              setApartmentNumber={setApartmentNumber}
              setZipCode={setZipCode}
              processor={"AuthorizeNet"}
            />
          </form>
        ) : (
          <>
            {props.customerPaymentProfiles.length === 1 ? (
              <div
                className={`payment_profile payment_profile_${props.customerPaymentProfiles[0].customerPaymentProfileId} Active`}
              >
                <div className="card_type">
                  {props.customerPaymentProfiles[0].payment.item.cardType}
                </div>
                <div className="card_number">
                  {props.customerPaymentProfiles[0].payment.item.cardNumber}
                </div>
              </div>
            ) : (
              <>
                {props.customerPaymentProfiles.map((i, index) => (
                  <div
                    key={index}
                    className="payment_profile"
                    id={`payment_profile_${i.customerPaymentProfileId}`}
                    onClick={() => setPaymentProfileForOrder(i)}
                  >
                    <div className="card_type">{i.payment.item.cardType}</div>
                    <div className="card_number">
                      {i.payment.item.cardNumber}
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        )}

        {props.invalidDayItems ||
        props.invalidTypeItems ||
        props.invalidTimeItems ? (
          <button id="submit" className="invalid" onClick={invalidItemsSubmit}>
            <span id="button-text">COMPLETE CHECKOUT</span>
          </button>
        ) : (
          <button id="submit" onClick={handleSubmit}>
            <span id="button-text">COMPLETE CHECKOUT</span>
          </button>
        )}
      </div>
    </div>
  );
}

export default CheckoutForm;
