export const convertToMilitaryTime = (inputTimeString) => {
  // Regular expression to match the "HH:MM AM/PM" format
  const timeRegex = /^(\d{1,2}):(\d{2})\s+(AM|PM)$/i;

  // Check if the input string matches the expected format
  const match = inputTimeString.match(timeRegex);

  if (!match) {
    // Invalid format, return an error or handle it as needed
    return null;
  }

  const [, hours, minutes, ampm] = match;

  // Parse hours and minutes into numbers
  const parsedHours = parseInt(hours, 10);
  const parsedMinutes = parseInt(minutes, 10);

  // Check if the time is "12:00 AM" and set military hours to 0
  let militaryHours = parsedHours === 12 && ampm.toUpperCase() === "AM" ? 0 : parsedHours;

  // Check if the time is in the PM and add 12 hours if needed
  if (ampm.toUpperCase() === "PM") {
    militaryHours += 12;
  }

  // Validate military time (hours should be between 0 and 23, minutes between 0 and 59)
  if (
    militaryHours < 0 ||
    militaryHours > 23 ||
    parsedMinutes < 0 ||
    parsedMinutes > 59
  ) {
    // Invalid military time, return an error or handle it as needed
    return null;
  }

  // Format the military time as "HH:MM:SS"
  const militaryTime = `${String(militaryHours).padStart(2, "0")}:${String(
    parsedMinutes
  ).padStart(2, "0")}:00`;

  return militaryTime;
};
