import React from "react";
import "../screens/home/home.scss";

function LoadingLogo() {
  return (
    <div className="loading_container">
      <img
        src={`/pictures/frankspizzaoakridge.png`}
        alt=""
        class="loading_logo"
      />
    </div>
  );
}

export default LoadingLogo;
