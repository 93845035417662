import React, { useState } from "react";
import AreYouSure from "./areYouSure/AreYouSure";

function OpenOrders(props) {
  const [showAreYouSureCancel, setShowAreYouSureCancel] = useState(false);
  const [showAreYouSureComplete, setShowAreYouSureComplete] = useState(false);
  const [orderData, setOrderData] = useState({});

  async function toggleAreYouSureComplete(order) {
    setOrderData(order);
    setShowAreYouSureComplete(true);
  }
  async function toggleAreYouSureCancel(order) {
    setOrderData(order);
    setShowAreYouSureCancel(true);
  }

  return (
    <>
      {showAreYouSureCancel && (
        <AreYouSure
          getOpenOrders={props.getOpenOrders}
          orderData={orderData}
          showAreYouSure={showAreYouSureCancel}
          setShowAreYouSure={setShowAreYouSureCancel}
          type={"cancel"}
        />
      )}
      {showAreYouSureComplete && (
        <AreYouSure
          getOpenOrders={props.getOpenOrders}
          orderData={orderData}
          showAreYouSure={showAreYouSureComplete}
          setShowAreYouSure={setShowAreYouSureComplete}
          type={"complete"}
        />
      )}
      <div className="orders_container">
        {props.openOrders ? (
          <>
            {props.openOrders?.map((i) => (
              <div className="card order" key={i.orderID}>
                <div className="card-header order_header d-flex justify-content-start w-100 align-items-center">
                  <div className="orderNumber fw-bold fs-3">
                    {"#" + i.orderNumber}
                  </div>
                  <div className="orderType fw-bold fs-3">{i.orderType}</div>
                  <div className="orderDetails fs-5 fw-bold">
                    {i.name + " - " + i.requestedDate}
                  </div>
                </div>
                <div id={`body_${i.orderID}`} className="card-body">
                  {i.orderType === "Delivery" && (
                    <div className="delivery_address card d-flex align-items-center justify-content-center">
                      <div className="address_label fs-5 fw-bold">Address:</div>
                      <div>
                        {i.streetAddress + " " + i.city + " " + i.stateName}
                      </div>
                    </div>
                  )}
                  <div className={`order_items order_${i.orderID}`}>
                    {i.items?.map((item) => (
                      <div
                        key={item.orderItemID}
                        className="item d-flex w-100 justify-content-between"
                      >
                        <div className="left">
                          <div className="item_name">
                            {item.sizeCode ? (
                              <h6>
                                {item.itemQuantity +
                                  "x - " +
                                  item.itemName +
                                  " - " +
                                  item.sizeCode}
                              </h6>
                            ) : (
                              <h6>
                                {item.itemQuantity + "x - " + item.itemName}
                              </h6>
                            )}
                          </div>
                          {item.extras?.map((extra) => (
                            <div
                              key={extra.orderItemExtraID}
                              className="item_extra"
                            >
                              {"-" + extra.extraName}
                              {extra.pizzaHalfCode && " " + extra.pizzaHalfCode}
                            </div>
                          ))}
                          {item.itemSpecialRequests && (
                            <div className="item_extra specialRequest">
                              {'"' + item.itemSpecialRequests + '"'}
                            </div>
                          )}
                        </div>
                        <div className="right">{"$" + item.itemTotalPrice}</div>
                      </div>
                    ))}

                    <div className="order_totals">
                      <div className="left">
                        <div>Subtotal</div>
                        <div>Tax</div>
                        <div>Tip</div>
                        <div>Final Total</div>
                      </div>
                      <div className="right">
                        <>
                          {i.discountedSubtotal ? (
                            <div className="price">
                              <s className="oldPrice">
                                ${i.orderSubtotal.toFixed(2)}
                              </s>
                              <div>${i.discountedSubtotal.toFixed(2)}</div>
                            </div>
                          ) : (
                            <div className="price">
                              ${i.orderSubtotal.toFixed(2)}
                            </div>
                          )}
                        </>
                        <>
                          {i.discountedTax ? (
                            <div className="price">
                              <s className="oldPrice">
                                ${i.orderTax.toFixed(2)}
                              </s>
                              <div>${i.discountedTax.toFixed(2)}</div>
                            </div>
                          ) : (
                            <div className="price">
                              ${i.orderTax.toFixed(2)}
                            </div>
                          )}
                        </>
                        <div className="price">${i.orderTip.toFixed(2)}</div>
                        <>
                          {i.discountedTotal ? (
                            <div className="price">
                              <s className="oldPrice">
                                ${i.orderTotal.toFixed(2)}
                              </s>
                              <div>${i.discountedTotal.toFixed(2)}</div>
                            </div>
                          ) : (
                            <div className="price">
                              ${i.orderTotal.toFixed(2)}
                            </div>
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                  <div className="order_footer">
                    <button
                      className="cancel"
                      onClick={() => toggleAreYouSureCancel(i)}
                    >
                      Cancel Order
                    </button>
                    <button
                      className="complete"
                      onClick={() => toggleAreYouSureComplete(i)}
                    >
                      Complete Order
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="mt-5 fs-5 fw-bold d-flex justify-content-center align-center">No Open Orders Today</div>
        )}
      </div>
    </>
  );
}

export default OpenOrders;
