import React, { useState, useContext } from "react";
import ApplicationContext from "../../ApplicationContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaWindowClose } from "react-icons/fa";
import { BsArrowRightSquareFill } from "react-icons/bs";
import "./cart.scss";
import CartItems from "./CartItems";
import StoreContext from "../../StoreContext";

function Cart(props) {
  const navigate = useNavigate();
  const appContext = useContext(ApplicationContext);
  const storeContext = useContext(StoreContext);

  const [isDisabled, setIsDisabled] = useState(false);

  function toggleCartBody() {
    if (
      document.getElementById("cart_popup").classList.contains("collapse") ===
      true
    ) {
      document.getElementById("cart_popup").classList.remove("collapse");
      props.setCartOpen(true);
    } else {
      document.getElementById("cart_popup").classList.add("collapse");
      props.setCartOpen(false);
    }
  }

  function checkout() {
    if (
      !appContext.userInfo.role ||
      !appContext.userInfo.email ||
      appContext.userInfo.role === "Guest"
    ) {
      props.setAuthKey("Guest");
      props.setShowAuthenticateModal(true);
    } else if (appContext.cart.CartPrice >= storeContext.orderMin) {
      var currentURL = window.location.href;
      if (currentURL.endsWith("/rewards")) {
        var newURL = currentURL.replace("/rewards", "/order");
        window.location.href = newURL;
      } else {
        navigate("order");
      }
    } else {
      toast.error(`Mimimum order amount is $${storeContext.orderMin}.`);
    }
  }

  return (
    <div className="card cart_popup fixed-bottom" id="cart_screen">
      {props.detailsAreLoading === false ? (
        <>
          {props.cartOpen ? (
            <div
              className="card-header checkout-card-header"
              onClick={checkout}
            >
              <div className="checkout_container" id="checkout_container">
                <p className="total">
                  Total: ${appContext.cart.CartPrice.toFixed(2)}
                </p>
                <div className="checkout_text">
                  <p className="text">Checkout</p>
                  <BsArrowRightSquareFill />
                </div>
              </div>
            </div>
          ) : (
            <div className="card-header" onClick={toggleCartBody}>
              <div className="viewcart_container">
                <p className="text">View My Cart</p>
                <div className="cart_data">
                  <p className="data">
                    Total: ${appContext.cart.CartPrice.toFixed(2)}
                  </p>
                  <p className="cartLength">
                    Items: {appContext.cartItems.length}
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="card-body collapse" id="cart_popup">
            <div className="cartpopup_container">
              <div className="header_wrapper">
                <h2 className="mycart">My Cart</h2>
                <div className="close_cart">
                  <FaWindowClose onClick={toggleCartBody} />
                </div>
              </div>

              <CartItems
                setSelectedItem={props.setSelectedItem}
                setShowEditItemModal={props.setShowEditItemModal}
                isDisabled={isDisabled}
                setIsDisabled={setIsDisabled}
                setAppContext={props.setAppContext}
                setCartOpen={props.setCartOpen}
              />
            </div>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default Cart;
