import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FaWindowClose } from "react-icons/fa";
import "./chargeOrderModal.scss";
import { toast } from "react-toastify";
import AreYouSure from "../areYouSure/AreYouSure";

function ChargeOrderModal(props) {
  const [showAreYouSureChargeItem, setShowAreYouSureChargeItem] =
    useState(false);
  const [chargeOrderText, setChargeOrderText] = useState("");
  const [chargeOrderAmount, setChargeOrderAmount] = useState(0.0);

  async function closeChargeOrderModal() {
    var activeButton = document.querySelector(".button.Active");
    if (activeButton) {
      activeButton.classList.remove("Active");
    }
    props.setChargeOrder(false);
  }

  const handleChange = (event) => {
    const { value } = event.target;
    setChargeOrderText(value.slice(0, 250)); // Limiting the input to 250 characters
  };

  const handleInputChargeChange = (event) => {
    const { value } = event.target;
    if (value) {
      setChargeOrderAmount(parseFloat(value));
    } else {
      setChargeOrderAmount(0.0);
    }
  };

  async function toggleAreYouSure() {
    if (
      chargeOrderText.length >= 4 &&
      chargeOrderAmount >= 0.5 &&
      chargeOrderAmount <= 50.0
    ) {
      setShowAreYouSureChargeItem(true);
    } else if (chargeOrderText.length < 4) {
      toast.error("Please enter at least 4 characters to explain.");
    } else if (chargeOrderAmount < 0.5) {
      toast.error("Minimum extra charge amount $0.50.");
    } else if (chargeOrderAmount > 50) {
      toast.error("Maximum extra charge amount $50.00.");
    }
  }

  return (
    <>
      {showAreYouSureChargeItem && (
        <AreYouSure
          showAreYouSure={showAreYouSureChargeItem}
          setShowAreYouSure={setShowAreYouSureChargeItem}
          setShowEditOrderScreen={props.setShowEditOrderScreen}
          orderData={props.orderData}
          chargeOrderText={chargeOrderText}
          chargeOrderAmount={chargeOrderAmount}
          type={"extra_charge"}
          getOrderHistory={props.getOrderHistory}
          setChargeOrder={props.setChargeOrder}
        />
      )}
      <Modal className="chargeOrderModal" show={props.chargeOrder}>
        <Modal.Body>
          <Modal.Header className="d-flex justify-content-end">
            <FaWindowClose
              onClick={closeChargeOrderModal}
              style={{ width: "2em", height: "2em" }}
              className="text-danger"
            />
          </Modal.Header>
          <div className="extraCharge">
            <div className="extraCharge_container">
              <div className="charge_inputs">
                <label>
                  How much $ would you like to Charge to this order?
                </label>
                <div className="input">
                  <div className="money">$</div>
                  <input
                    placeholder="0.00"
                    type="number"
                    onChange={handleInputChargeChange}
                  />
                </div>
              </div>

              <div className="textbox d-flex flex-column">
                <div>What is the reason for this charge?</div>
                <textarea
                  value={chargeOrderText}
                  onChange={handleChange}
                  maxLength={250}
                  rows={4}
                  cols={50}
                  style={{ fontSize: "20px" }}
                />
                <p>Remaining characters: {250 - chargeOrderText.length}</p>
              </div>
            </div>

            <div className="button submit" onClick={() => toggleAreYouSure()}>
              Submit
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ChargeOrderModal;
