import React, { useState } from "react";
import {
  IoIosMail,
  IoIosUnlock,
  IoMdPhonePortrait,
  IoIosPersonAdd,
  IoIosPeople,
} from "react-icons/io";
import { handlePhoneNumberChange } from "../../utils/authenticate/HandlePhoneNumberChange";
import axios from "axios";
import { APPLICATION, BASE_API, STORE_NAME_CODE } from "../../constants";
import { toast } from "react-toastify";
import { CreateGuid } from "../../utils/authenticate/CreateGUID";

function Register(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const role = "User";

  async function handleSubmit(e) {
    e.preventDefault();

    var userID = CreateGuid();

    try {
      const storeName = STORE_NAME_CODE;

      await axios.post(
        `${BASE_API}api/User/Register`,
        {
          userID,
          firstName,
          lastName,
          email,
          phoneNumber,
          password,
          role,
          storeName,
        },
        {
          headers: {
            Application: APPLICATION,
          },
        }
      );
      toast.success("Registration Successful");
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhoneNumber("");
      setPassword("");
      props.setAuthKey("Login");
    } catch (err) {
      toast.error("Registration error.");
    }
  }

  async function handlePhoneNumber(e) {
    var number = handlePhoneNumberChange(e);
    setPhoneNumber(number);
  }

  async function submit() {
    if (props.setUserChoice !== undefined) {
      props.setUserChoice(true);
    }
  }

  return (
    <div className="register_page loginregisterModal">
      <form onSubmit={handleSubmit}>
        <div className="container">
          <div className="input_fields">
            <label>
              <IoIosPersonAdd style={{ width: "2em", height: "2em" }} />
            </label>
            <input
              type="text"
              placeholder="First Name"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              required
            />
          </div>
          <div className="input_fields">
            <label>
              <IoIosPeople style={{ width: "2em", height: "2em" }} />
            </label>
            <input
              type="text"
              placeholder="Last Name"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              required
            />
          </div>
          <div className="input_fields">
            <label>
              <IoIosMail style={{ width: "2em", height: "2em" }} />
            </label>
            <input
              type="text"
              placeholder="Email"
              id="register_email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
            />
          </div>
          <div className="input_fields">
            <label>
              <IoMdPhonePortrait style={{ width: "2em", height: "2em" }} />
            </label>
            <input
              type="text"
              placeholder="Phone Number"
              onChange={handlePhoneNumber}
              pattern="\d{3}-\d{3}-\d{4}|\d{10}"
              value={phoneNumber}
              required
            />
          </div>
          <div className="input_fields">
            <label>
              <IoIosUnlock style={{ width: "2em", height: "2em" }} />
            </label>
            <input
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
          </div>
          <div className="submit">
            <button type="submit" className="user" onClick={submit}>
              Register
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Register;
