import React, { useContext, useEffect, useState } from "react";
import MenuContext from "../../MenuContext";

function MenuItems(props) {
  const menuContext = useContext(MenuContext);

  const [menuItems, setMenuItems] = useState([]);
  const [categoryDescription, setCategoryDescription] = useState();

  useEffect(() => {
    if (menuContext.items) {
      var menuitemsArray = [];
      for (var i = 0; i < menuContext.items.length; i++) {
        var itemName = menuContext.items[i].itemName;
        if (menuContext.items[i].itemSubCategory) {
          if (
            menuContext.items[i].itemSubCategory === props.subCategoryName &&
            !menuitemsArray.some((item) => item.itemName === itemName)
          ) {
            menuitemsArray.push(menuContext.items[i]);
          }
        } else {
          if (
            menuContext.items[i].itemCategory === props.categoryName &&
            !menuitemsArray.some((item) => item.itemName === itemName)
          ) {
            menuitemsArray.push(menuContext.items[i]);
          }
        }
      }

      setMenuItems(menuitemsArray);
    }
  }, [props]);

  async function toggleItemModal(item) {
    item.active = "Active";
    props.setSelectedItem(item);
    props.setShowItemModal(true);
  }

  let cat;
  useEffect(() => {
    if (props.menuName) {
      cat = menuContext.categories[props.menuName];
      setCategoryDescription(
        (cat = cat.find((category) => category.category === props.categoryName))
      );
    }
  }, [props]);

  return (
    <div className="menu_items">
      {categoryDescription && (
        <>
          {categoryDescription.categoryDescription !== null && (
            <div className="categoryDescription">
              <em>{categoryDescription.categoryDescription}</em>
            </div>
          )}
        </>
      )}
      {menuItems?.map((i, index) => (
        <div
          key={index}
          className="card"
          onClick={() => toggleItemModal(i)}
        >
          <div className="px-4 py-2">
            <h4 className="fw-bold itemName">{i.itemName}</h4>
            {i.itemDescription && (
              <p className="mb-1 itemDescription">
                <em>{i.itemDescription}</em>
              </p>
            )}
            <p className="mb-0 fw-bold itemPrice">{"$" + i.itemPrice.toFixed(2)}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default MenuItems;
